import React, { FC, useEffect, useState, useRef, useCallback } from "react";

import {
  changeParticipationPaymentStatus,
  fetchAllRegistrations,
  getAllRegistrations,
  getAllRegistrationsLoading,
} from "../../../features/tournoi/tournoiSlice";

import { useAppDispatch, useAppSelector } from "../../../store";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Participation } from "../../../types";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-community";
import { useLocation } from "react-router-dom";

interface RegistrationListProps {}

const RegistrationList: FC<RegistrationListProps> = () => {
  const registrations = useAppSelector(getAllRegistrations);

  const loading = useAppSelector(getAllRegistrationsLoading);

  const location = useLocation();
  const dispatch = useAppDispatch();

  const [isOpenedConfirmModal, setIsOpenedConfirmModal] = useState(false);
  const [selectedParticipation, setSelectedParticipation] =
    useState<Participation | null>(null);

  const handleConfirmation = async () => {
    if (selectedParticipation) {
      const status = isCancellation(selectedParticipation)
        ? "cancelled"
        : "admin";
      await dispatch(
        changeParticipationPaymentStatus({
          participationId: selectedParticipation.id,
          status,
        })
      );

      setIsOpenedConfirmModal(false); // Fermez la boîte de dialogue après avoir traité l'action
    }
  };

  const statusButtonRenderer = (
    params: ICellRendererParams<any, Participation>
  ) => {
    const currentParticipation: Participation = params.data;

    const statusButtonLabel = isCancellation(currentParticipation)
      ? "Annuler paiement"
      : "Inscrire paiement";

    return (
      <Grid>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setSelectedParticipation(currentParticipation);
            setIsOpenedConfirmModal(true);
          }}
        >
          <Typography style={{ fontSize: "11px" }}>
            {statusButtonLabel}
          </Typography>
        </Button>
      </Grid>
    );
  };

  const gridRef = useRef<AgGridReact<Participation[]>>(null);

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "Nom",
      flex: 2,
      sortable: true,
      valueGetter: (params) =>
        params.data.players.name + " " + params.data.players.surname,
    },
    {
      headerName: "Points",
      sortable: true,
      field: "players.points",
      width: 80,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        // Convertir les valeurs de chaîne en nombres avant de les comparer
        const numA = parseInt(valueA, 10);
        const numB = parseInt(valueB, 10);

        if (numA === numB) {
          return 0;
        }
        if (numA < numB) {
          return -1;
        }
        return 1;
      },
    },
    {
      headerName: "Licence",
      sortable: true,
      field: "players.licenceId",
      width: 100,
    },
    {
      headerName: "Club",
      sortable: true,
      field: "players.club",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Cat",
      sortable: true,
      field: "players.categorie",
      width: 60,
    },
    {
      headerName: "Sexe",
      sortable: true,
      field: "players.sexe",
      width: 70,
    },
    {
      headerName: "Tableau",
      field: "tableau.name",
      sortable: true,
      width: 80,
    },
    {
      headerName: "Email",
      sortable: true,
      field: "players.email",
      width: 220,
    },
    {
      headerName: "Date d'inscription",
      width: 175,
      sortable: true,
      comparator: (valueA, valueB, nodeA, nodeB) => {
        return new Date(nodeA.data.dateInscription) >
          new Date(nodeB.data.dateInscription)
          ? 1
          : -1;
      },
      valueGetter: (params) =>
        new Date(params.data.dateInscription).toLocaleString(),
    },
    {
      headerName: "Paiement",
      sortable: true,
      valueGetter: (params: ValueGetterParams) => {
        let payment = "Non payé";
        switch (params.data.paymentStatus) {
          case "registration":
            payment = "Payé à l'inscription";
            break;
          case "admin":
            payment = "Payé";
            break;
          case "cancelled":
            payment = "Annulé";
            break;
        }
        return payment;
      },
      width: 160,
    },
    {
      headerName: "Action",
      cellRenderer: statusButtonRenderer,
      width: 150,
    },
  ]);

  useEffect(() => {
    dispatch(fetchAllRegistrations());
  }, [location.pathname, dispatch]);

  const onBtnExport = useCallback(() => {
    const now: string = new Date().toLocaleString();
    gridRef.current!.api.exportDataAsCsv({
      fileName: `inscriptions_${now}`,
    });
  }, []);

  if (gridRef.current! && gridRef.current!.api) {
    gridRef.current!.api.sizeColumnsToFit();
  }

  return (
    <Stack spacing={2} sx={{ height: "calc(100vh - 100px)" }}>
      {loading && !isOpenedConfirmModal ? ( // Afficher le loader si loading est true
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Paper
            className="ag-theme-alpine"
            sx={{ width: "100%", flexGrow: 1, overflowX: "auto" }}
          >
            <AgGridReact
              domLayout="autoHeight"
              rowData={registrations}
              columnDefs={columnDefs}
              ref={gridRef}
            ></AgGridReact>
          </Paper>
          {selectedParticipation && (
            <Dialog
              maxWidth="xs"
              onClose={() => {
                setIsOpenedConfirmModal(false);
              }}
              open={isOpenedConfirmModal}
            >
              <DialogContent>
                {isCancellation(selectedParticipation!)
                  ? "Voulez-vous vraiment marquer l'inscription comme non payée ?"
                  : "Voulez-vous vraiment marquer l'inscription comme payée ?"}
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={() => {
                    setIsOpenedConfirmModal(false);
                  }}
                >
                  Annuler
                </Button>
                <Button onClick={handleConfirmation}>Ok</Button>
              </DialogActions>
            </Dialog>
          )}
          <Button variant="outlined" onClick={onBtnExport}>
            Exporter sous excel
          </Button>
        </>
      )}
    </Stack>
  );
};

export default RegistrationList;

const isCancellation = (participation: Participation) =>
  participation.paymentStatus === "registration" ||
  participation.paymentStatus === "admin";
