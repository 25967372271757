import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "../../store";
import {
  BlockedMatchModalParams,
  FreedMatchModalParams,
  Matchs,
  OccupiedTableModalParams,
  Players,
  Table,
} from "../../types";
import tournoiApi from "../../services/tournoiApi";
import checkIsMatchLaunchable from "./isMatchLaunchable";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import firestoreInstance from "../../services/firestoreInstance";

export type MatchsState = {
  matchs: Array<Matchs>;
  currentMatchs: Array<Matchs>;
  blockedMatchModalParams: BlockedMatchModalParams | null;
  occupiedTableModalParams: OccupiedTableModalParams | null;
  freedMatchModalParams: FreedMatchModalParams | null;
  tables: Array<Table>;
};

const initialState: MatchsState = {
  matchs: [],
  currentMatchs: [],
  blockedMatchModalParams: null,
  occupiedTableModalParams: null,
  freedMatchModalParams: null,
  tables: [],
};

export const fetchTables = createAsyncThunk<
  Table[],
  void,
  { state: RootState }
>("tournoi/fetchTables", async (_, thunkApi) => {
  let tables: Table[] = [];
  try {
    const tablesSnapshot = await getDocs(
      query(collection(firestoreInstance, "table"))
    );
    tables = tablesSnapshot.docs.map((doc) => {
      return {
        ...(doc.data() as Table),
        ...{ tableId: parseInt(doc.id) },
      };
    });
  } catch (e) {
    console.log(e);
  }

  return tables;
});

export const fetchMatchs = createAsyncThunk<
  Matchs[],
  void,
  { state: RootState }
>("tournoi/fetchMatchs", async (_, thunkApi) => {
  await thunkApi.dispatch(fetchTables());
  let matchs: Matchs[] = [];
  try {
    const matchsSnapshot = await getDocs(
      query(
        collection(firestoreInstance, "matchs"),
        where("status", "!=", "TERMINE"),
        orderBy("dateCreation")
      )
    );
    matchs = matchsSnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        ...{ id: doc.id },
        ...{
          dateCreation:
            doc.data().dateCreation && doc.data().dateCreation.toDate(),
        },
        ...{
          dateLancement:
            doc.data().dateLancement && doc.data().dateLancement.toDate(),
        },
      } as Matchs;
    });
    console.log("matchs", matchs);
  } catch (e) {
    console.log(e);
  }

  return matchs;
});

// Juste avant le tournoi
export const createMatch = createAsyncThunk<
  any,
  { match: Matchs },
  { state: RootState; dispatch: AppDispatch }
>("tournoi/createMatch", async ({ match }, { getState, dispatch }) => {
  const { isMatchLaunchable, blockedMatchModalParams } = checkIsMatchLaunchable(
    match,
    getState().matchs.matchs
  );

  if (!isMatchLaunchable) {
    match.status = "DOUBLON";
    for (const bp of blockedMatchModalParams.blockingPlayers) {
      await updateDoc(doc(firestoreInstance, "matchs", bp.match.id), {
        bloquant: true,
      });
    }
  }

  match.tableau = getState().tournoi.boards.find(
    (board) => board.id === match.tableauId
  );
  match.tour = getState().tournoi.rounds.find(
    (round) => round.id === match.tourId
  );
  match.matchsPlayerParticipations = match.matchsPlayerParticipations.map(
    (mpp) => {
      return {
        ...mpp,
        players: getState().player.players.find((p) => p.id === mpp.playersId),
      };
    }
  );

  await addDoc(collection(firestoreInstance, "matchs"), match);

  // a remplacer par une ecoute sur la collection matchs
  await dispatch(fetchMatchs());
  return;
});

// Juste avant le tournoi
export const changeMatchStatus = createAsyncThunk<
  any,
  Matchs,
  { state: RootState; dispatch: AppDispatch }
>("tournoi/changeMatchStatus", async (match, { getState, dispatch }) => {
  // match non lancé. On vérifie s'il est lançable
  if (
    match.status === "CREE" ||
    match.status === "DOUBLON" ||
    match.status === "LIBERE"
  ) {
    const { isMatchLaunchable, blockedMatchModalParams } =
      checkIsMatchLaunchable(match, getState().matchs.matchs);

    // on compte le nombre de matchs en cours sur la table sur laquelle on veut lancer le match
    const matchsOnTable = getState().matchs.matchs.filter(
      (m) => m.status === "ENCOURS" && m.tableId === match.tableId && m.tableId
    );
    const nbMatchTable = matchsOnTable.length;

    //si le match est lançable on le lance

    if (isMatchLaunchable && nbMatchTable === 0) {
      await updateDoc(doc(firestoreInstance, "matchs", match.id), {
        status: "ENCOURS",
        dateLancement: new Date(),
      });
      dispatch(fetchMatchs());
      return;
    }

    if (isMatchLaunchable && nbMatchTable !== 0) {
      // match lancable mais table occupée, on affiche la modale associée
      dispatch(
        matchsSlice.actions.launchOccupiedTableModal({
          blockedTable: match.tableId!,
          blockingMatchs: matchsOnTable,
        })
      );
    }

    // match non lançable.. est-il bloqué par des joueurs ?
    if (blockedMatchModalParams.blockingPlayers.length !== 0) {
      for (const bp of blockedMatchModalParams.blockingPlayers) {
        await updateDoc(doc(firestoreInstance, "matchs", bp.match.id), {
          bloquant: true,
        });
      }

      await updateDoc(doc(firestoreInstance, "matchs", match.id), {
        status: "DOUBLON",
      });

      dispatch(fetchMatchs());
      dispatch(
        matchsSlice.actions.launchBlockedMatchModal(blockedMatchModalParams)
      );
    }
  } else {
    await updateDoc(doc(firestoreInstance, "matchs", match.id), {
      status: "TERMINE",
      dateFin: new Date(),
    });

    // match bloquant terminé. On libère les matchs bloqués et on affiche une modale
    if (match.bloquant === true) {
      // on détermine les matchs libéré parmi les matchs en doublon
      const blockedMatchs = getState().matchs.matchs.filter(
        (m) => m.status === "DOUBLON"
      );
      // y a t'il des joueurs en communs avec le match qui se termine ?
      let freedMatchs: Array<Matchs> = [];
      let freedPlayers: Array<Players> = [];

      for (const bm of blockedMatchs) {
        let isFreed = false;
        bm.matchsPlayerParticipations.forEach((bmmp) => {
          match.matchsPlayerParticipations.forEach((mmp) => {
            if (bmmp.playersId === mmp.playersId) {
              freedPlayers.push(mmp.players!);
              isFreed = true;
            }
          });
        });
        if (isFreed) {
          freedMatchs.push(bm);
          await updateDoc(doc(firestoreInstance, "matchs", bm.id), {
            status: "LIBERE",
          });
        }
      }
      if (freedMatchs.length !== 0) {
        dispatch(
          matchsSlice.actions.launchFreedMatchModal({
            freedPlayers,
            freedMatchs,
          })
        );
      }
    }
    dispatch(fetchMatchs());
    return;
  }
});

// Juste avant le tournoi
export const changeMatchTable = createAsyncThunk<
  any,
  { tableId: number; matchId: string },
  { dispatch: AppDispatch }
>("tournoi/changeMatchTable", async ({ tableId, matchId }, { dispatch }) => {
  await updateDoc(doc(firestoreInstance, "matchs", matchId), {
    tableId,
  });

  dispatch(fetchMatchs());
  return;
});

const matchsSlice = createSlice({
  name: "tournoi",
  initialState,
  reducers: {
    launchBlockedMatchModal: (
      state: MatchsState,
      blockedMatchModalParams: PayloadAction<BlockedMatchModalParams>
    ) => {
      state.blockedMatchModalParams = blockedMatchModalParams.payload;
    },
    closeBlockedMatchModal: (state: MatchsState) => {
      state.blockedMatchModalParams = null;
    },
    launchOccupiedTableModal: (
      state: MatchsState,
      occupiedTableModalParams: PayloadAction<OccupiedTableModalParams>
    ) => {
      state.occupiedTableModalParams = occupiedTableModalParams.payload;
    },
    closeOccupiedTableModal: (state: MatchsState) => {
      state.occupiedTableModalParams = null;
    },
    launchFreedMatchModal: (
      state: MatchsState,
      freedMatchModalParams: PayloadAction<FreedMatchModalParams>
    ) => {
      state.freedMatchModalParams = freedMatchModalParams.payload;
    },
    closeFreedMatchModal: (state: MatchsState) => {
      state.freedMatchModalParams = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchMatchs.fulfilled,
        (state: MatchsState, action: PayloadAction<Matchs[]>) => {
          state.matchs = action.payload;
        }
      )
      .addCase(
        fetchTables.fulfilled,
        (state: MatchsState, action: PayloadAction<Table[]>) => {
          state.tables = action.payload;
        }
      );
  },
});

export default matchsSlice.reducer;

export const {
  closeBlockedMatchModal,
  closeOccupiedTableModal,
  closeFreedMatchModal,
} = matchsSlice.actions;

export const getMatchs = (state: RootState) => state.matchs.matchs;

export const getPlayersWithCurrentMatchs = (state: RootState): Players[] => {
  const players: Players[] = [];

  state.matchs.matchs
    .filter((match) => match.status === "ENCOURS")
    .forEach((match) => {
      match.matchsPlayerParticipations.forEach((mpp) => {
        if (mpp.players) {
          const matchPlayerParticipation = { ...mpp, match };
          const player = {
            ...mpp.players,
            matchPlayerParticipation: [matchPlayerParticipation],
          };
          players.push(player);
        }
      });
    });

  return players.sort((p1, p2) => p1.name.localeCompare(p2.name));
};

export const getBlockedMatchModalParams = (state: RootState) =>
  state.matchs.blockedMatchModalParams?.blockingPlayers.length !== null
    ? state.matchs.blockedMatchModalParams
    : null;

export const getOccupiedTableModalParams = (state: RootState) =>
  state.matchs.occupiedTableModalParams?.blockingMatchs.length !== null
    ? state.matchs.occupiedTableModalParams
    : null;

export const getFreedMatchModalParams = (state: RootState) =>
  state.matchs.freedMatchModalParams?.freedMatchs.length !== null
    ? state.matchs.freedMatchModalParams
    : null;

const getRoomId = (_state: RootState, roomId: number) => roomId;

const getLiveMatchs = (state: RootState) =>
  state.matchs.matchs.filter((match) => match.status === "ENCOURS");

const getAllTables = (state: RootState) => state.matchs.tables;

export const getTablesWithMatchsGroupedByRows = createSelector(
  [getLiveMatchs, getRoomId, getAllTables],
  (matchs, roomId, tables) => {
    const tablesFromRoom = tables.filter((table) => table.roomId === roomId);

    const tablesWithMatchs = tablesFromRoom.map((table) => {
      const matchsOnTable = matchs.filter(
        (match) => match.tableId === table.tableId
      );
      return { ...table, ...{ matchs: matchsOnTable } };
    });

    // Group matchs by rows
    const tablesGroupedByRows: { [row: number]: Table[] } = {};
    tablesWithMatchs.forEach((table) => {
      const row = table.row;
      if (row !== undefined) {
        if (!tablesGroupedByRows[row]) {
          tablesGroupedByRows[row] = [];
        }
        tablesGroupedByRows[row].push(table);
      }
    });

    // Sort matchs within each row by columns
    Object.values(tablesGroupedByRows).forEach((rowTables) => {
      rowTables.sort((a, b) => (a.column > b.column ? 1 : -1));
    });

    return tablesGroupedByRows;
  }
);

export const getAvailableRooms = createSelector(
  [getLiveMatchs, getAllTables],
  (matchs, tables) => {
    const matchsWithTable = matchs.map((match) => {
      const table = tables.find((table) => table.tableId === match.tableId);
      return { ...match, ...{ table } };
    });

    const roomIdsDistincts: Array<number> = [];
    matchsWithTable.forEach((match) => {
      if (
        match.table!.roomId &&
        !roomIdsDistincts.includes(match.table!.roomId)
      ) {
        roomIdsDistincts.push(match.table!.roomId);
      }
    });
    if (roomIdsDistincts.length === 0) {
      roomIdsDistincts.push(1);
    }

    return roomIdsDistincts.sort((a, b) => a - b);
  }
);
