import React, { FC, useEffect } from "react";

import { getBoards, fetchBoards } from "../../features/tournoi/tournoiSlice";

import { useAppDispatch, useAppSelector } from "../../store";

import {
  Box,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Tableau } from "../../types";

interface BoardListProps {}

const BoardList: FC<BoardListProps> = () => {
  const boards = useAppSelector(getBoards);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchBoards());
  }, [dispatch]);

  return (
    <Box sx={{ overflow: "auto" }}>
      <Box sx={{ width: "100%", display: "table" }}>
        <TableContainer sx={{ dmaxHeight: 600 }} component={Paper}>
          <Table stickyHeader aria-label="inscriptions">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "10px" }}>#</TableCell>
                <TableCell align="right">Nom</TableCell>
                <TableCell align="right">Jour</TableCell>
                <TableCell align="right">Fin pointage</TableCell>
                <TableCell align="right">Inscrits</TableCell>
                <TableCell align="right">Places</TableCell>
                <TableCell align="right">Remplissage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!boards || boards.length === 0 ? (
                <TableRowsLoader rowsNum={18} />
              ) : (
                boards!.map((board: Tableau) => {
                  const tooltipText = `Tableau rempli à ${Math.round(
                    (100 * board!.nbParticipants!) / parseInt(board!.nbPlace)
                  )}%. Il reste ${
                    parseInt(board!.nbPlace) - board!.nbParticipants!
                  } places`;
                  return (
                    <TableRow
                      key={board!.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={{ width: "10" }}>{board!.name}</TableCell>

                      <TableCell width={60} align="right">
                        {board!.label}
                      </TableCell>
                      <TableCell width={80} align="right">
                        {new Date(board!.date).toLocaleDateString("fr-FR", {
                          dateStyle: "full",
                        })}
                      </TableCell>
                      <TableCell width={80} align="right">
                        {board!.heureLimite}
                      </TableCell>
                      <TableCell width={80} align="right">
                        {board!.nbParticipants}
                      </TableCell>
                      <TableCell width={80} align="right">
                        {board!.nbPlace}
                      </TableCell>
                      <TableCell width={80} align="right">
                        <Tooltip title={tooltipText}>
                          <LinearProgress
                            sx={{ height: 10 }}
                            variant="determinate"
                            value={
                              (100 * board!.nbParticipants!) /
                              parseInt(board!.nbPlace)
                            }
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

const TableRowsLoader: React.FC<{ rowsNum: number }> = ({ rowsNum }) => {
  return (
    <>
      {[...Array(rowsNum)].map((_, index) => (
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton animation="wave" variant="text" />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default BoardList;
