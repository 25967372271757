import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, Outlet } from "react-router-dom"; // Use Navigate, Route, and RouteProps from 'react-router-dom'
import { auth } from "../services/firebaseAuth";

interface ProtectedRouteProps {}

const ProtectedRoute: React.FC<ProtectedRouteProps> = () => {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    // Show loading indicator while authentication is in progress
    return <div>Loading...</div>;
  }

  if (error) {
    // Handle authentication error
    console.error("Authentication error:", error);
    return <div>Authentication error</div>;
  }

  return user ? <Outlet /> : <Navigate to="/signup" />;
};

export default ProtectedRoute;
