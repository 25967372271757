import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import {
  fetchedPlayer,
  getAccessibleBoards,
} from "../../features/player/playerSlice";

import { useAppSelector } from "../../store";
import {
  Typography,
  Box,
  Alert,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";

interface RegisterValidationProps {}

const RegisterValidation: FC<RegisterValidationProps> = () => {
  const player = useAppSelector(fetchedPlayer);
  const boards = useAppSelector(getAccessibleBoards);
  const navigate = useNavigate();

  return (
    <Stack spacing={1}>
      <Alert severity="success">Votre inscription a réussi.</Alert>
      <Typography variant="body2">
        Vous êtes inscrits aux tableaux suivants :
      </Typography>

      <Box sx={{ overflow: "auto" }}>
        <Box sx={{ width: "100%", display: "table" }}>
          <TableContainer component={Paper}>
            <Table aria-label="inscriptions">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "10px" }}>#</TableCell>
                  <TableCell align="right">Nom</TableCell>
                  <TableCell align="right">Jour</TableCell>
                  <TableCell align="right">Fin pointage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {player!.participation!.map((participation) => {
                  const board = boards.find(
                    (board) => board.id === participation.tableauId
                  );

                  return (
                    <TableRow
                      key={board!.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={{ width: "10" }}>{board!.name}</TableCell>

                      <TableCell width={60} align="right">
                        {board!.label}
                      </TableCell>
                      <TableCell width={80} align="right">
                        {new Date(board!.date).toLocaleDateString("fr-FR", {
                          dateStyle: "full",
                        })}
                      </TableCell>
                      <TableCell width={80} align="right">
                        {board!.heureLimite}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Button
        onClick={() => {
          navigate("/register");
        }}
      >
        Inscrire un autre joueur
      </Button>
    </Stack>
  );
};

export default RegisterValidation;
