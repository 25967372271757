import React, { FC, useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import TennisTable from "./TennisTables";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import TablePlayers from "./TablePlayers";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  fetchMatchs,
  getAvailableRooms,
  getTablesWithMatchsGroupedByRows,
} from "../../features/matchs/matchsSlice";
import {
  fetchTournoi,
  getLiveRefreshPeriodInSeconds,
} from "../../features/tournoi/tournoiSlice";

interface LiveProps {}

const Live: FC<LiveProps> = () => {
  const dispatch = useAppDispatch();
  const [roomIdx, setRoomIdx] = useState(0);
  const availableRooms = useAppSelector(getAvailableRooms);
  const tablesByRow = useAppSelector((state: RootState) =>
    getTablesWithMatchsGroupedByRows(state, availableRooms[roomIdx])
  );

  const refreshInterval = useAppSelector(getLiveRefreshPeriodInSeconds);
  console.log("refreshInterval", refreshInterval);

  useEffect(() => {
    dispatch(fetchMatchs());
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (roomIdx < availableRooms.length - 1) {
        setRoomIdx((prevIndex) => prevIndex + 1);
      } else {
        setRoomIdx(0);
        dispatch(fetchMatchs());
        dispatch(fetchTournoi());
      }
    }, refreshInterval);

    // Nettoyez l'intervalle lors du démontage du composant
    return () => clearInterval(interval);
  }, [roomIdx, availableRooms, dispatch, refreshInterval]);

  return (
    tablesByRow && (
      <>
        <Box
          display="flex"
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Box
            sx={{
              borderColor: "primary.light",
              borderStyle: "solid",
              borderWidth: "3px",
              borderRadius: 5,
              marginBottom: "10px",
              textAlign: "center",
              padding: "8px",
              backgroundColor: "rgb(229, 246, 253)",
            }}
          >
            <Typography variant="h5">
              Salle {availableRooms[roomIdx]}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "rgb(229, 246, 253)",
            paddingLeft: "55px",
            paddingTop: "40px",
          }}
        >
          <Grid2 container direction={"column"} spacing={7}>
            {Object.keys(tablesByRow).map((row) => (
              <Grid2 container key={row} spacing={6} sx={{ flexGrow: 1 }}>
                {tablesByRow[parseInt(row)].map((table, idx) => (
                  <Grid2 xs key={idx}>
                    <TennisTable tableId={table.tableId!}>
                      <>
                        {table.matchs![0] && (
                          <TablePlayers
                            matchPlayers={
                              table.matchs![0].matchsPlayerParticipations
                            }
                          />
                        )}
                      </>
                    </TennisTable>
                  </Grid2>
                ))}
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </>
    )
  );
};

export default Live;
