import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import { auth, loginEmailPassword } from "../../services/firebaseAuth";
import isEmail from "validator/lib/isEmail";
import isStrongPassword from "validator/lib/isStrongPassword";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import { FirebaseError } from "firebase/app";

const Login = () => {
  // Load browser history
  const navigate = useNavigate();

  const [signInSuccessMsg, setSignInSuccessMsg] = useState("");

  // Get authentication variables
  const [user, loading, error] = useAuthState(auth);

  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isEmailDirty, setIsEmailDirty] = useState<boolean>(false);

  const [password, setPassWord] = useState<string>("");
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [isPasswordDirty, setIsPasswordDirty] = useState<boolean>(false);

  useEffect(() => {
    // Possibly add a loading screen
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading, navigate]);

  const handleEmailChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const emailValue: string = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(isEmail(emailValue));
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const passwordValue: string = e.target.value;
    setPassWord(passwordValue);
    setIsPasswordValid(isStrongPassword(passwordValue));
  };

  // Reroute user to the home page if already signed in
  useEffect(() => {
    // Possibly add a loading screen
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading, navigate]);

  return (
    <Grid container direction={"column"} spacing={5}>
      <Grid item>
        <Alert severity="info">
          Afin de pouvoir vous inscrire au tournoi ou accéder à certaine partie
          du site, merci de vous connecter.
        </Alert>
      </Grid>

      <Grid item>
        <TextField
          type="email"
          label="Email"
          required={true}
          fullWidth
          value={email}
          onChange={handleEmailChange}
          onBlur={() => setIsEmailDirty(true)}
          error={isEmailDirty && !isEmailValid}
        />
      </Grid>
      <Grid item>
        <TextField
          type="password"
          label="Mot de passe"
          autoFocus={true}
          required={true}
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          onBlur={() => setIsPasswordDirty(true)}
          error={isPasswordDirty && !isPasswordValid}
          helperText={
            "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial."
          }
        />
      </Grid>
      <Grid item>
        {signInSuccessMsg.length > 0 && (
          <Grid marginBottom={2}>
            <Typography variant="body2" color="red">
              {signInSuccessMsg}
            </Typography>
          </Grid>
        )}
        <Button
          variant="contained"
          disabled={!isPasswordValid || !isEmailValid}
          onClick={async () => {
            try {
              const result = await loginEmailPassword(email, password);
              if (!result) {
                setSignInSuccessMsg(
                  "Erreur lors de la connexion. Réessayez plus tard."
                );
              }
            } catch (error) {
              if (
                error instanceof FirebaseError &&
                error.code === "auth/invalid-credential"
              ) {
                setSignInSuccessMsg("Combinaison email/mot de passe invalide.");
              } else {
                setSignInSuccessMsg(
                  "Erreur lors de la connexion. Réessayez plus tard."
                );
              }
            }
          }}
        >
          Se connecter
        </Button>
      </Grid>

      <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
        <Grid item>
          <Link to="/signup">Pas de compte. En créer un</Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
