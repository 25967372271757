import React, { FC } from "react";

import { useAppSelector } from "../../store";

import { getTournoi } from "../../features/tournoi/tournoiSlice";

interface RulesProps {}

const Rules: FC<RulesProps> = () => {
  const tournoi = useAppSelector(getTournoi);

  if (!tournoi) {
    return <></>;
  }

  return (
    <iframe
      title="Règlement"
      src={encodeURI(`/${tournoi?.reglement}#toolbar=0`)}
      width="100%"
      height={"600px"}
    />
  );
};

export default Rules;
