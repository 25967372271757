import React, { FC, useEffect, useCallback } from "react";
//import styles from './Layout.module.css';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../store";
import { getTournoi, fetchTournoi } from "../../features/tournoi/tournoiSlice";
import {
  setAdmin,
  admin,
  clearSession,
} from "../../features/session/sessionSlice";
import { Chip, Divider, Menu, MenuItem } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout } from "../../services/firebaseAuth";

interface LayoutProps {}

const drawerWidth = 240;
const navItems = [
  { label: "Inscription", path: "/register" },
  { label: "Tableaux", path: "/boards" },
  { label: "Règlement", path: "/rules" },
  { label: "Live", path: "/live" },
  { label: "Joueurs Live", path: "/live-player" },
  { label: "Nous contacter", path: "/contactus" },
];

const adminNavItems = [
  { label: "Liste des inscrits", path: "/admin/registrations" },
  { label: "Liste des inscriptions", path: "/admin/registration-list" },
  { label: "Inscrits par tableaux", path: "/admin/board-registrations" },
  { label: "Gérer le tournoi", path: "/admin/tournament-managment" },
  { label: "Gérer les tableaux", path: "/admin/boards-managment" },
  { label: "Gérer les matchs", path: "/admin/matchs" },
];

const Layout: FC<LayoutProps> = (props: LayoutProps) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(admin);
  const [user] = useAuthState(auth);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchAdmin = useCallback(async () => {
    await dispatch(fetchTournoi());
    if (!user) return;
    const customClaims = await user.getIdTokenResult();
    const admin = customClaims.claims.admin ? true : (false as boolean);
    dispatch(setAdmin(admin));
  }, [dispatch, user]);

  useEffect(() => {
    fetchAdmin();
  }, [fetchAdmin]);

  const tournoi = useAppSelector(getTournoi);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const adminMenu = (
    <>
      <Divider
        orientation="vertical"
        sx={{
          bgcolor: "#fff",
          minHeight: "inherit",
          marginLeft: "5px",
          marginRight: "5px",
        }}
        flexItem
      />
      <Button
        sx={{ color: "#fff" }}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Admin
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {adminNavItems.map((item) => (
          <MenuItem
            key={item.label}
            onClick={() => {
              navigate(item.path);
              handleClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  const adminDrawerMenu = (
    <>
      <Divider>
        <Chip label="ADMIN" />
      </Divider>

      {adminNavItems.map((item) => (
        <ListItem key={item.label} disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => {
              navigate(item.path);
            }}
          >
            <ListItemText primary={item.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </>
  );

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => {
                navigate(item.path);
              }}
            >
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
        {user ? (
          <ListItem key={"logout"} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => {
                dispatch(clearSession());
                logout();
              }}
            >
              <ListItemText primary={"Se déconnecter"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <ListItem key={"login"} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => {
                navigate("/login");
              }}
            >
              <ListItemText primary={"Se connecter"} />
            </ListItemButton>
          </ListItem>
        )}
        {isAdmin && adminDrawerMenu}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {tournoi?.label}
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            {navItems.map((item) => (
              <Button
                key={item.label}
                sx={{ color: "#fff" }}
                onClick={() => {
                  navigate(item.path);
                }}
              >
                {item.label}
              </Button>
            ))}
            {isAdmin && adminMenu}
            {user ? (
              <Button
                key={"logout"}
                sx={{ color: "#fff" }}
                onClick={() => {
                  dispatch(clearSession());
                  logout();
                }}
              >
                {"Se déconnecter"}
              </Button>
            ) : (
              <Button
                key={"login"}
                sx={{ color: "#fff" }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                {"Se connecter"}
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Container component="main" sx={{ p: 3 }} maxWidth={false}>
        <Toolbar />
        {tournoi ? <Outlet /> : null}
      </Container>
    </Box>
  );
};

export default Layout;
