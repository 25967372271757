import { Autocomplete, TextField } from "@mui/material";
import React, { FC } from "react";

import { getPlayers } from "../../../features/player/playerSlice";
import { useAppSelector } from "../../../store";
import { Players } from "../../../types";

type Props = {
  label: string;
  currentPlayerId: string | null;
  onPlayerSelect: (player: Players | null) => void;
};

const PlayerSelector: FC<Props> = ({
  label,
  onPlayerSelect,
  currentPlayerId,
}) => {
  const players = useAppSelector(getPlayers);

  return (
    <>
      <Autocomplete
        sx={{ flexGrow: 1 }}
        disablePortal
        value={players.find((p) => p.id === currentPlayerId) || null}
        //inputValue={currentPlayerId ? currentPlayerId : ""}
        autoComplete={true}
        autoHighlight={true}
        autoSelect={true}
        options={players}
        onChange={(_, value) => {
          onPlayerSelect(value);
        }}
        renderInput={(params) => (
          <TextField sx={{ fontSize: 12 }} {...params} label={label} />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
          >{`${option.name} ${option.surname} - ${option.licenceId}`}</li>
        )}
        getOptionLabel={(option: Players) =>
          `${option.name} ${option.surname} - ${option.licenceId}`
        }
        noOptionsText="Aucun joueur trouvé"
      />
    </>
  );
};

export default PlayerSelector;
