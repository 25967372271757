import React, { FC, useEffect } from "react";
import { Navigate } from "react-router-dom";
import {
  fetchTournoi,
  getAreOpenedRegistrations,
} from "../../features/tournoi/tournoiSlice";
import { useAppDispatch, useAppSelector } from "../../store";

type Props = {};

const Home: FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const areOpenedRegistrations = useAppSelector(getAreOpenedRegistrations);

  useEffect(() => {
    dispatch(fetchTournoi);
  }, [dispatch]);

  if (areOpenedRegistrations) {
    return <Navigate to="/register" />;
  } else {
    return <Navigate to="/live-player" />;
  }
};

export default Home;
