import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Stack, Button } from "@mui/material";

interface RegisterThanksProps {}

const RegisterThanks: FC<RegisterThanksProps> = () => {
  const navigate = useNavigate();

  return (
    <Stack spacing={1}>
      <Alert severity="success">Votre inscription a réussi.</Alert>
      <Alert severity="info">Un e-mail de confirmation vous a été envoyé</Alert>
      <Button
        onClick={() => {
          navigate("/register");
        }}
      >
        Inscrire un autre joueur
      </Button>
    </Stack>
  );
};

export default RegisterThanks;
