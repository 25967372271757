import { PlayerState } from "./playerSlice";

import { Tableau } from "../../types";

export const isRegistered = (boardId: string, state: PlayerState) => {
  return (
    // tableau trouvé dans les participations du joueurs et non déselectionné
    (state.player?.participation?.find((p) => p.tableauId === boardId) !==
      undefined &&
      state.unselectedBoardIds.find((b) => b === boardId) === undefined) ||
    // tableau sélectioné
    state.selectedBoardIds.find((b) => b === boardId) !== undefined
  );
};

export const isDisabled = (
  boardId: string,
  state: PlayerState,
  isAdmin: boolean
) => {
  // tableau.obligatoire  ne mettre à disabled qu'en cas d'inscription déjà effectuée et déjà inscrit sur un autre tableau
  return (
    // déja inscrit sur au moins 2 tableau mais pas encore sur ce tableau
    (registrationsDate(boardId, state).length >= 2 &&
      !isRegistered(boardId, state)) ||
    // non admin tableau fermé ou full et pas encore inscrit sur ce tableau -> on ne peut plus s'inscrire
    (!isAdmin &&
      (infosTableau(boardId, state).ferme ||
        infosTableau(boardId, state).isFull) &&
      !isRegistered(boardId, state)) ||
    // non admin, déjà inscrit au tableau auparavant. on ne peut plus supprimer => devient inutile car un désactive tout dès qu'il y a une inscriptio
    (!isAdmin &&
      state.player?.participation?.find((p) => p.tableauId === boardId) !==
        undefined) ||
    // non admin, déjà inscrit à au moins un tableau, on désactive toute modification
    (!isAdmin && state.player!.participation!.length > 0) ||
    // on ne peut pas supprimer un tableau obligatoire si au moins une autre inscription
    (infosTableau(boardId, state).obligatoire &&
      isRegistered(boardId, state) &&
      registrationsDate(boardId, state).length >= 2)
  );
  //  || !this.compatible(tableau)
};

export const registrationsDate = (boardId: string, state: PlayerState) => {
  const boardDate = infosTableau(boardId, state).date;
  return state.accessibleBoards
    .filter((ab) => ab.date === boardDate)
    .filter((ab) => isRegistered(ab.id, state));
};

const infosTableau = (boardId: string, state: PlayerState): Tableau => {
  return state.accessibleBoards.find((ab) => ab.id === boardId)!;
};
