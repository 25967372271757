import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export type SessionState = {
  admin: boolean;
  initialized: boolean;
};

const initialState: SessionState = {
  admin: false,
  initialized: false,
};

const tournoiSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setAdmin: (state: SessionState, admin: PayloadAction<boolean>) => {
      if (!state.initialized) {
        state.admin = admin.payload;
        state.initialized = true;
      }
    },
    clearSession: (state: SessionState) => {
      state.admin = false;
      state.initialized = false;
    },
  },
});

export default tournoiSlice.reducer;

export const { setAdmin, clearSession } = tournoiSlice.actions;

export const admin = (state: RootState) => state.session.admin;
