import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import playerSlice, { PlayerState } from "./features/player/playerSlice";
import tournoiSlice, { TournoiState } from "./features/tournoi/tournoiSlice";
import sessionSlice, { SessionState } from "./features/session/sessionSlice";
import matchsSlice, { MatchsState } from "./features/matchs/matchsSlice";

export const store = configureStore({
  reducer: {
    player: playerSlice,
    tournoi: tournoiSlice,
    session: sessionSlice,
    matchs: matchsSlice,
  },
});

export type RootState = {
  player: PlayerState;
  tournoi: TournoiState;
  session: SessionState;
  matchs: MatchsState;
};
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
