import React, { FC } from "react";

import { useAppSelector } from "../../store";

import { getTournoi } from "../../features/tournoi/tournoiSlice";
import { Alert, Stack, Typography } from "@mui/material";

interface ContactUsProps {}

const ContactUs: FC<ContactUsProps> = () => {
  const tournoi = useAppSelector(getTournoi);
  const mailto = `mailto:${tournoi?.mail}`;

  if (!tournoi) {
    return <></>;
  }

  return (
    <Stack spacing={1}>
      <Alert severity="info">
        <Typography>
          Pour toute question concernant le tournoi, vous pouvez nous contacter
          à l'adresse <a href={mailto}>{tournoi.mail}</a>
        </Typography>
        <Typography>Le tournoi se déroulera {tournoi.adresse}</Typography>
      </Alert>
      <iframe
        title="Gymnase des Pyramides"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2630.1302619804305!2d2.043467715551309!3d48.76030867927799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6811fe3921929%3A0x40fbc61888f1307b!2sCentre%20Sportif%20des%20Pyramides!5e0!3m2!1sfr!2sfr!4v1575378406725!5m2!1sfr!2sfr"
        height={550}
      ></iframe>
    </Stack>
  );
};

export default ContactUs;
