import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Participation, Players } from "../../types";
import { Tableau } from "../../types";
import { isRegistered, isDisabled } from "./playerHelper";
import { groupBy } from "../../helpers/arrayHelper";
import cloudFunctions from "../../services/firebaseCloudFunctions";
import { httpsCallable } from "firebase/functions";
import { FirebaseError } from "firebase/app";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import firestoreInstance from "../../services/firestoreInstance";

type BoardDynamicParams = {
  selected: boolean;
  selectable: boolean;
  disabled: boolean;
};

export type AccessibleBoard = Tableau & BoardDynamicParams;

export type PlayerState = {
  player: Players | null;
  status: "idle" | "succeeded" | "loading" | "failed" | "noplayer";
  paymentStatus: "idle" | "succeeded" | "loading" | "failed" | "noplayer";
  error: string;
  accessibleBoards: Tableau[];
  selectedBoardIds: string[];
  unselectedBoardIds: string[];
  playersWithRegistrations: Players[];
  players: Players[];
  playersWithRegistrationsLoading: boolean;
};

const initialState: PlayerState = {
  player: null,
  status: "idle",
  paymentStatus: "idle",
  error: "",
  accessibleBoards: [],
  selectedBoardIds: [],
  unselectedBoardIds: [],
  playersWithRegistrations: [],
  players: [],
  playersWithRegistrationsLoading: false,
};

export const fetchPlayerFromLicence = createAsyncThunk<
  any,
  string,
  { state: RootState }
>("player/fetchPlayer", async (licenceId: string, thunkApi) => {
  const findPlayerFromLicence = httpsCallable<unknown, Players>(
    cloudFunctions,
    "findplayerfromlicence"
  );

  try {
    const response = await findPlayerFromLicence({ licenceId });
    return response.data as Players;
  } catch (error: unknown) {
    if (error instanceof FirebaseError && error.message === "not-found") {
      return null;
    }
    throw error;
  }
});

export const fetchPlayersWithRegistrations = createAsyncThunk<
  Players[],
  void,
  { state: RootState }
>("player/fetchPlayersWithRegistrations", async (_, thunkApi) => {
  const currentTournamentId = thunkApi.getState().tournoi.tournoi?.id;

  const getPlayersWithRegistrations = httpsCallable<unknown, Players[]>(
    cloudFunctions,
    "getPlayersWithRegistrations"
  );

  const response = await getPlayersWithRegistrations({
    tournamentId: currentTournamentId,
  });

  return response.data;
});

export const fetchPlayers = createAsyncThunk<
  Players[],
  void,
  { state: RootState }
>("player/fetchPlayers", async (_, thunkApi) => {
  const players = (
    await getDocs(
      query(
        collection(firestoreInstance, "players"),
        orderBy("name"),
        orderBy("surname")
      )
    )
  ).docs.map((doc) => ({ ...(doc.data() as Players), ...{ id: doc.id } }));
  return players as Players[];
});

export const updateMail = createAsyncThunk<
  unknown,
  string,
  { state: RootState }
>("player/updtateMail", async (email: string, { getState, dispatch }) => {
  const playerId = getState().player.player?.id;

  const licenceId = getState().player.player?.licenceId;

  const updateMailFunction = httpsCallable<unknown, unknown>(
    cloudFunctions,
    "updateemail"
  );

  let response;
  try {
    response = await updateMailFunction({ playerId, email });
  } catch (error: unknown) {
    throw error;
  }

  await dispatch(fetchPlayerFromLicence(licenceId!));
  return response.data;
});

export const fetchAccessibleBoards = createAsyncThunk<
  Tableau[],
  string,
  { state: RootState }
>("player/fetchAccessibleBoards", async (playerId: string, thunkApi) => {
  const currentTournamentId = thunkApi.getState().tournoi.tournoi?.id;

  const accessibleBoards = httpsCallable<unknown, Tableau[]>(
    cloudFunctions,
    "accessibleBoards"
  );

  const response = await accessibleBoards({
    playerId,
    tournamentId: currentTournamentId,
  });
  return response.data.sort((a, b) =>
    a.name.localeCompare(b.name)
  ) as Tableau[];
});

export const saveRegistrations = createAsyncThunk<
  any,
  string,
  { state: RootState }
>("player/saveRegistrations", async (_, { getState, dispatch }) => {
  const selectedBoardIds = getState().player.selectedBoardIds;
  const unselectedBoardIds = getState().player.unselectedBoardIds;
  const playerId = getState().player.player?.id;

  const saveRegistrations = httpsCallable<unknown, Tableau[]>(
    cloudFunctions,
    "saveRegistrations"
  );

  const response = await saveRegistrations({
    tournamentId: getState().tournoi.tournoi?.id,
    playerId,
    selectedBoardIds,
    unselectedBoardIds,
  });

  return response.data;
});

export const createPayment = createAsyncThunk<
  any,
  string,
  { state: RootState }
>("player/createPayment", async (_, { getState, dispatch }) => {
  const selectedBoardIds = getState().player.selectedBoardIds;
  const playerId = getState().player.player?.id;
  const licenceId = getState().player.player?.licenceId;

  const getPaymentUrl = httpsCallable<unknown, any>(
    cloudFunctions,
    "getPaymentUrl"
  );

  const tournamentId = getState().tournoi.tournoi?.id;

  try {
    const origin = window.location.origin;
    const response = await getPaymentUrl({
      licenceId,
      playerId,
      tournamentId,
      selectedBoardIds,
      success_url: `${origin}/register/thanks?session_id={CHECKOUT_SESSION_ID}"`,
      cancel_url: origin,
    });

    window.location.href = response.data.url;
  } catch (error: unknown) {
    throw error;
  }
});

const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    cleanState: (state: PlayerState) => {
      state.selectedBoardIds = [];
      state.unselectedBoardIds = [];
    },

    switchState: (state: PlayerState, boardId: PayloadAction<string>) => {
      const id = boardId.payload;
      const isSelected =
        state.selectedBoardIds.find((boardId) => id === boardId) !== undefined;
      const isUnSelected =
        state.unselectedBoardIds.find((boardId) => id === boardId) !==
        undefined;

      const isAlreadyRegistered =
        state.player?.participation?.find((p) => p.tableauId === id) !==
        undefined;

      if (!isAlreadyRegistered && !isSelected) {
        // non inscrit et non selectionné -> on enregistre une nouvelle inscription
        state.selectedBoardIds.push(id);

        const boardDate = state.accessibleBoards.find((b) => b.id === id)?.date;
        const mandatoryBoardWithDate = state.accessibleBoards.find(
          (b) => b.id !== id && b.obligatoire && b.date === boardDate
        );

        if (
          mandatoryBoardWithDate &&
          !mandatoryBoardWithDate.ferme &&
          !mandatoryBoardWithDate.isFull &&
          !isRegistered(mandatoryBoardWithDate.id, state)
        ) {
          state.selectedBoardIds.push(mandatoryBoardWithDate.id);
        }
      } else if (!isAlreadyRegistered && isSelected) {
        // non inscrit et sélectionné -> on supprime la nouvelle inscription
        state.selectedBoardIds = state.selectedBoardIds.filter(
          (boardId) => boardId !== id
        );
      } else if (isAlreadyRegistered && !isUnSelected) {
        state.unselectedBoardIds.push(id);
      } else if (isAlreadyRegistered && isUnSelected) {
        state.unselectedBoardIds = state.unselectedBoardIds.filter(
          (boardId) => boardId !== id
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayerFromLicence.pending, (state: PlayerState, action) => {
        state.status = "loading";
      })
      .addCase(
        fetchPlayerFromLicence.fulfilled,
        (state: PlayerState, action: PayloadAction<Players>) => {
          if (!action.payload) {
            state.status = "noplayer";
            state.player = {} as Players;
            state.error =
              "Numéro de licence non trouvé, réessayez avec un autre ou contactez-nous par mail sur tournoi@sqyping.fr";
          } else {
            state.status = "succeeded";
            state.player = action.payload;
          }
        }
      )
      .addCase(
        fetchPlayerFromLicence.rejected,
        (state: PlayerState, action) => {
          state.status = "failed";
          if (action.error.message) {
            state.error = action.error.message;
          }
        }
      )
      .addCase(fetchPlayersWithRegistrations.pending, (state: PlayerState) => {
        state.playersWithRegistrations = [];
        state.playersWithRegistrationsLoading = true;
      })
      .addCase(
        fetchPlayersWithRegistrations.fulfilled,
        (state: PlayerState, action: PayloadAction<Players[]>) => {
          state.playersWithRegistrations = action.payload;
          state.playersWithRegistrationsLoading = false;
        }
      )
      .addCase(
        fetchPlayers.fulfilled,
        (state: PlayerState, action: PayloadAction<Players[]>) => {
          state.players = action.payload;
        }
      );
    builder
      .addCase(
        fetchAccessibleBoards.fulfilled,
        (state: PlayerState, action: PayloadAction<Tableau[]>) => {
          state.status = "succeeded";
          state.accessibleBoards = action.payload.map((board) => board);
        }
      )
      .addCase(fetchAccessibleBoards.pending, (state: PlayerState, action) => {
        state.status = "loading";
      });

    builder
      .addCase(createPayment.fulfilled, (state: PlayerState) => {
        state.paymentStatus = "succeeded";
      })
      .addCase(createPayment.pending, (state: PlayerState) => {
        state.paymentStatus = "loading";
      });
  },
});

export default playerSlice.reducer;

export const { switchState, cleanState } = playerSlice.actions;

export const fetchedPlayer = (state: RootState) => state.player.player;
export const getPlayers = (state: RootState) => state.player.players;
export const fetchedNoPlayer = (state: RootState) =>
  state.player.status === "noplayer";
export const fetchedErrorMessage = (state: RootState) => state.player.error;
export const fetchedSearchError = (state: RootState) =>
  state.player.status === "failed";

export const accessibleBoardsByDate = (state: RootState) => {
  const accessibleBoards: AccessibleBoard[] = state.player.accessibleBoards.map(
    (board) =>
      ({
        ...board,
        selected: isRegistered(board.id, state.player),
        disabled: isDisabled(board.id, state.player, state.session.admin),
      } as AccessibleBoard)
  );

  return groupBy(accessibleBoards, (item) => item.date.toString());
};

export const prices = (state: RootState) => {
  const selectedBoardIds = state.player.selectedBoardIds;
  const unselectedBoardIds = state.player.unselectedBoardIds;
  const participations = state.player.player?.participation;

  // recuperer les anciens tableaux
  const formerBoardsIds = participations?.map((p) => p.tableauId) || [];

  // recupérer les tableaux existants + ceux sélectionnés - les déselectionnés
  const allSelectedBoardIds = [...formerBoardsIds, ...selectedBoardIds].filter(
    (id) => !unselectedBoardIds.includes(id)
  );

  // state.player.accessibleBoards.forEach((board) => {
  return {
    formerPrice: calculatePrice(formerBoardsIds, state.player.accessibleBoards),
    newPrice: calculatePrice(
      allSelectedBoardIds,
      state.player.accessibleBoards
    ),
    priceDifference:
      calculatePrice(allSelectedBoardIds, state.player.accessibleBoards) -
      calculatePrice(formerBoardsIds, state.player.accessibleBoards),
  };
};

const calculatePrice = (boardIds: string[], boards: Tableau[]) => {
  let nbSelected = 0;
  let hasFullPrice = false;

  boards.forEach((board) => {
    if (boardIds.includes(board.id)) {
      nbSelected++;
      if (board.fullPrice) {
        hasFullPrice = true;
      }
    }
  });

  if (nbSelected === 1) {
    return hasFullPrice ? 10 : 9;
  } else if (nbSelected === 2) {
    return hasFullPrice ? 18 : 16;
  } else if (nbSelected === 3) {
    return hasFullPrice ? 26 : 25;
  } else if (nbSelected === 4) {
    return hasFullPrice ? 34 : 32;
  }

  return 0;
};

export const hasCurrentActions = (state: RootState): boolean => {
  return (
    state.player.selectedBoardIds.length +
      state.player.unselectedBoardIds.length >
    0
  );
};

export const hasRegistrations = (state: RootState): boolean => {
  return (
    state.player.player?.participation !== undefined &&
    state.player.player?.participation?.length > 0
  );
};

export const getAccessibleBoards = (state: RootState) =>
  state.player.accessibleBoards;

export const getPlayerLoading = (state: RootState) =>
  state.player.status === "loading";

export const getPaymentLoading = (state: RootState) =>
  state.player.paymentStatus === "loading";

export const getPlayersWithRegistrationsLoading = (state: RootState) =>
  state.player.playersWithRegistrationsLoading;

export const getPlayerWithRegistrations = (state: RootState) =>
  state.player.playersWithRegistrations
    .filter((player: Players) => {
      // si aucune participation on filtre le joueur
      if (!player.participation || player.participation.length === 0) {
        return false;
      }

      // on filtre les participations sans tableau dans le tournoi courant
      const tempParticipations = player.participation.filter(
        (participation: Participation) => {
          if (!participation.tableau) {
            return false;
          }
          return true;
        }
      );

      // on vérifie à nouveau s'il reste des participations
      if (!tempParticipations || tempParticipations.length === 0) {
        return false;
      }

      // on garde le joueur
      return true;
    })
    .sort((p1, p2) => {
      if (p1.licenceId === p2.licenceId) {
        if (
          p1!.participation![0].tableau!.name <
          p2!.participation![0].tableau!.name
        ) {
          return -1;
        }
        return 1;
      }
      if (p1!.licenceId! < p2!.licenceId!) {
        return -1;
      }
      return 1;
    });
