import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { FC, useEffect } from "react";

import { fetchRounds, getRounds } from "../../../features/tournoi/tournoiSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Tour } from "../../../types";

type Props = {
  value: string;
  handleRoundChange: (roundId: string) => void;
};

const RoundSelector: FC<Props> = ({ value, handleRoundChange }) => {
  const dispatch = useAppDispatch();
  const rounds = useAppSelector(getRounds);

  useEffect(() => {
    dispatch(fetchRounds());
  }, [dispatch]);

  useEffect(() => {
    if (rounds.length === 0 || value !== "") {
      return;
    }
    handleRoundChange(rounds[0].id);
  }, [rounds, handleRoundChange, value]);

  return (
    <FormControl>
      <InputLabel id="selected-round-label">Sélectionnez un tour</InputLabel>
      <Select
        labelId="selected-round-label"
        sx={{ minWidth: "200px" }}
        id="selected-round"
        value={value}
        label="Sélectionner un tour"
        onChange={(event: SelectChangeEvent) => {
          handleRoundChange(event.target.value);
        }}
      >
        {rounds.map((round: Tour) => (
          <MenuItem key={round.id} value={round.id}>
            {round.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RoundSelector;
