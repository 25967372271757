import React, { FC, useEffect } from "react";
import {
  fetchedPlayer,
  fetchAccessibleBoards,
  accessibleBoardsByDate,
  hasCurrentActions,
  saveRegistrations,
  hasRegistrations,
  prices,
  getPlayerLoading,
  getPaymentLoading,
  createPayment,
  fetchPlayerFromLicence,
  cleanState,
} from "../../features/player/playerSlice";
import { getTournoi } from "../../features/tournoi/tournoiSlice";
import { admin } from "../../features/session/sessionSlice";

import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../store";
import { RegisterListBoardsDate } from "./RegisterListBoardsDate";
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Stack,
} from "@mui/material";

interface RegisterSelectProps {}

const RegisterSelect: FC<RegisterSelectProps> = () => {
  const player = useAppSelector(fetchedPlayer);
  const accessibleBoardByDate = useAppSelector(accessibleBoardsByDate);
  const hasCurrentActionsState = useAppSelector(hasCurrentActions);
  const pricesState = useAppSelector(prices);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tournoi = useAppSelector(getTournoi);
  const isAdmin = useAppSelector(admin);
  const playerHasRegistrations = useAppSelector(hasRegistrations);
  const loading = useAppSelector(getPlayerLoading);
  const paymentLoading = useAppSelector(getPaymentLoading);

  useEffect(() => {
    // si pas de player redirection vers la page d'inscription
    if (!player) {
      navigate("/register");
    }
    if (player?.id) {
      dispatch(fetchAccessibleBoards(player?.id));
      dispatch(cleanState());
    }
  }, [dispatch, navigate]);

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <Stack spacing={3} alignItems="center">
      {!isAdmin && playerHasRegistrations && (
        <Alert severity="warning">
          Vous êtes déjà inscrits à au moins un tableau. Pour toute
          modification, contactez-nous par mail à l'adresse{" "}
          <a href="mailto:{tournoi?.mail}">{tournoi?.mail}</a>
        </Alert>
      )}
      {Object.keys(accessibleBoardByDate)
        .sort()
        .map((date) => (
          <RegisterListBoardsDate
            key={date}
            date={date}
            accessibleBoards={accessibleBoardByDate[date]}
          />
        ))}
      {isAdmin ? (
        <Stack spacing={3} alignItems="center" direction="row">
          <Chip
            label={`Déjà payé ${pricesState.formerPrice} €`}
            color="primary"
            variant="outlined"
          />
          <Button
            variant="contained"
            disabled={!hasCurrentActionsState}
            onClick={async () => {
              const resultAction = await dispatch(saveRegistrations("SAVE"));
              if (saveRegistrations.fulfilled.match(resultAction)) {
                await dispatch(fetchAccessibleBoards(player?.id));
                await dispatch(fetchPlayerFromLicence(player?.licenceId!));
                navigate("/register/validation");
              }
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              Sauvegarder les inscriptions {pricesState.priceDifference} €
            </Box>
          </Button>
          <Chip
            label={`Nouveau prix ${pricesState.newPrice} €`}
            color="success"
            variant="outlined"
          />
        </Stack>
      ) : (
        <>
          <Alert severity="warning">
            Attention, le remboursement n'est possible que sur présentation d'un
            certificat médical.
          </Alert>
          <Button
            variant="contained"
            disabled={!hasCurrentActionsState || paymentLoading}
            onClick={async () => {
              await dispatch(createPayment("SAVE"));
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              Payer l'inscription {pricesState.newPrice} €
              {paymentLoading && (
                <CircularProgress
                  color="info"
                  size={24}
                  sx={{ marginLeft: 1 }}
                />
              )}
            </Box>
          </Button>
        </>
      )}
    </Stack>
  );
};

export default RegisterSelect;
