import React, { FC, useEffect, useState } from "react";

import { useAppDispatch } from "../../../store";
import { fetchPlayers } from "../../../features/player/playerSlice";
import { fetchMatchs, createMatch } from "../../../features/matchs/matchsSlice";
import { Button, Stack } from "@mui/material";
import PlayerSelector from "./PlayerSelector";
import RoundSelector from "./RoundSelector";
import BoardSelector from "./BoardSelector";
import { Matchs } from "../../../types";
import MatchsList from "./MatchsList";

type Props = {};

const AdminMatchs: FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();

  const [currentBoardId, setCurrentBoardId] = useState<string>("");
  const [currentRoundId, setCurrentRoundId] = useState<string>("");
  const [playersIds, setPlayersIds] = useState<Array<string | null>>([
    null,
    null,
    null,
    null,
  ]);

  useEffect(() => {
    dispatch(fetchPlayers());
    dispatch(fetchMatchs());
  }, [dispatch]);

  return (
    <Stack spacing={2} direction="column">
      <Stack spacing={2} direction="row">
        <BoardSelector
          value={currentBoardId}
          handleBoardChange={(boardId: string) => setCurrentBoardId(boardId)}
        />
        <RoundSelector
          value={currentRoundId}
          handleRoundChange={(roundId: string) => setCurrentRoundId(roundId)}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        {playersIds.map((_, index) => (
          <PlayerSelector
            key={index}
            label={`Joueur ${index + 1}`}
            currentPlayerId={playersIds[index]}
            onPlayerSelect={(player) => {
              setPlayersIds(
                playersIds.map((pi, idx) => {
                  if (idx === index) {
                    return player?.id || null;
                  } else {
                    return pi;
                  }
                })
              );
            }}
          />
        ))}
        <Button
          variant="contained"
          disabled={playersIds.filter((id) => id !== null).length === 0}
          onClick={async () => {
            const newMatch: Matchs = {
              dateCreation: new Date(),
              status: "CREE",
              tableauId: currentBoardId,
              tourId: currentRoundId,
              matchsPlayerParticipations: playersIds
                .filter((pid) => pid !== null)
                .map((pid, idx) => {
                  return {
                    ordre: idx + 1,
                    playersId: pid,
                  };
                }),
            };
            await dispatch(createMatch({ match: newMatch }));
            setPlayersIds([null, null, null, null]);
          }}
        >
          Créer Match
        </Button>
      </Stack>
      <MatchsList />
    </Stack>
  );
};

export default AdminMatchs;
