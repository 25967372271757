import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import Layout from "./components/Layout/Layout";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Register from "./components/Register/Register";
import RegisterLicenceValidation from "./components/RegisterLicenceValidation/RegisterLicenceValidation";
import RegisterSelect from "./components/RegisterSelect/RegisterSelect";
import RegisterValidation from "./components/RegisterValidation/RegisterValidation";
import BoardList from "./components/BoardList/BoardList";
import Rules from "./components/Rules/Rules";
import ContactUs from "./components/ContactUs/ContactUs";
import Live from "./components/Live/Live";
import LivePlayer from "./components/LivePlayer/LivePlayer";
import BoardRegistrations from "./components/Admin/BoardRegistrations/BoardRegistrations";
import Registrations from "./components/Admin/Registrations/Registrations";
import AdminBoards from "./components/Admin/Boards/AdminBoards";
import AdminMatchs from "./components/Admin/AdminMatchs/AdminMatchs";
import AdminTournament from "./components/Admin/Tournament/AdminTournament";
import RegistrationList from "./components/Admin/RegistrationList/RegistrationList";
import Home from "./components/Home/Home";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import RegisterThanks from "./components/RegisterThanks/RegisterThanks";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          element: <ProtectedRoute />,
          children: [
            {
              path: "/",
              element: <Home />,
            },
            {
              path: "/register",
              element: <Register />,
            },
            {
              path: "/register/licence-validation",
              element: <RegisterLicenceValidation />,
            },
            {
              path: "/register/select",
              element: <RegisterSelect />,
            },
            {
              path: "/register/validation",
              element: <RegisterValidation />,
            },
            {
              path: "/register/thanks",
              element: <RegisterThanks />,
            },
            {
              path: "/admin/board-registrations",
              element: <BoardRegistrations />,
            },
            { path: "/admin/registrations", element: <Registrations /> },
            { path: "/admin/registration-list", element: <RegistrationList /> },
            { path: "/admin/boards-managment", element: <AdminBoards /> },
            {
              path: "/admin/tournament-managment",
              element: <AdminTournament />,
            },
            { path: "/admin/matchs", element: <AdminMatchs /> },
          ],
        },
        {
          path: "/boards",
          element: <BoardList />,
        },
        { path: "/rules", element: <Rules /> },
        { path: "/contactus", element: <ContactUs /> },
        { path: "/live", element: <Live /> },
        { path: "/live-player", element: <LivePlayer /> },
        { path: "/login", element: <Login /> },
        { path: "/signup", element: <Signup /> },
        { path: "*", element: <Home /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
