import React, { useEffect, useState } from "react";

import { Typography, TextField, Stack, TextFieldProps } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

type Props = {
  label: string;
  type: TextFieldProps["type"];
  onChange(newValue: string): void;
};

function EditableField({ label, type, onChange }: Props) {
  const FONT_SIZE = 9;
  const DEFAULT_INPUT_WIDTH = 200;

  const [name, setName] = React.useState(label);
  const [isNameFocused, setIsNamedFocused] = useState(false);
  const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

  useEffect(() => {
    if (name.length * FONT_SIZE > DEFAULT_INPUT_WIDTH) {
      setInputWidth((name.length + 1) * FONT_SIZE);
    } else {
      setInputWidth(DEFAULT_INPUT_WIDTH);
    }
  }, [name]);

  return (
    <>
      {!isNameFocused ? (
        <Stack direction="row" spacing={1}>
          <Typography>{name}</Typography>
          <EditIcon
            fontSize="small"
            onClick={() => {
              setIsNamedFocused(true);
            }}
          />
        </Stack>
      ) : (
        <TextField
          variant="standard"
          type={type}
          autoFocus
          value={name}
          InputProps={{
            style: { width: `${inputWidth}px` },
          }}
          onChange={(event) => setName(event.target.value)}
          onBlur={(event) => {
            setIsNamedFocused(false);
            onChange(event.target.value);
          }}
        />
      )}
    </>
  );
}

export default EditableField;
