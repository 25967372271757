import React, { FC, useEffect, useState, useRef, useCallback } from "react";

import {
  getBoardRegistrations,
  fetchBoardRegistrations,
  getBoardRegistrationsLoading,
} from "../../../features/tournoi/tournoiSlice";

import { useAppDispatch, useAppSelector } from "../../../store";

import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { Participation, Tableau } from "../../../types";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef } from "ag-grid-community";
import { useLocation } from "react-router-dom";

interface BoardRegistrationsProps {}

const BoardRegistrations: FC<BoardRegistrationsProps> = () => {
  const boardRegistrations = useAppSelector(getBoardRegistrations);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [currentBoardId, setCurrentBoardId] = useState<string>("");
  const [currentBoardName, setCurrentBoardName] = useState<string>("");
  const [currentBoardRegistrations, setCurrentBoardRegistrations] = useState<
    Participation[]
  >([]);
  const loading = useAppSelector(getBoardRegistrationsLoading);

  useEffect(() => {
    if (boardRegistrations.length === 0) {
      return;
    }
    setCurrentBoardId(boardRegistrations[0].id);
    setCurrentBoardName(boardRegistrations[0].name);
    setCurrentBoardRegistrations(boardRegistrations[0].participation!);
  }, [boardRegistrations]);

  const gridRef = useRef<AgGridReact<Participation[]>>(null);

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "Nom",
      flex: 2,
      sortable: true,
      valueGetter: (params) =>
        params.data.players.name + " " + params.data.players.surname,
    },
    {
      headerName: "Points",
      sortable: true,
      field: "players.points",
      width: 80,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        // Convertir les valeurs de chaîne en nombres avant de les comparer
        const numA = parseInt(valueA, 10);
        const numB = parseInt(valueB, 10);

        if (numA === numB) {
          return 0;
        }
        if (numA < numB) {
          return -1;
        }
        return 1;
      },
    },
    {
      headerName: "Licence",
      sortable: true,
      field: "players.licenceId",
      width: 100,
    },
    {
      headerName: "Club",
      sortable: true,
      field: "players.club",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Categorie",
      sortable: true,
      field: "players.categorie",
      width: 100,
    },
    {
      headerName: "Sexe",
      sortable: true,
      field: "players.sexe",
      width: 80,
    },
    {
      headerName: "Email",
      sortable: true,
      field: "players.email",
      flex: 2,
    },
    {
      headerName: "Date d'inscription",
      flex: 2,
      sortable: true,
      comparator: (valueA, valueB, nodeA, nodeB) => {
        return new Date(nodeA.data.dateInscription) >
          new Date(nodeB.data.dateInscription)
          ? 1
          : -1;
      },
      valueGetter: (params) =>
        new Date(params.data.dateInscription).toLocaleString(),
    },
  ]);

  useEffect(() => {
    dispatch(fetchBoardRegistrations());
  }, [location.pathname, dispatch]);

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentBoardId(event.target.value);

    setCurrentBoardRegistrations(
      boardRegistrations.find((board) => board.id === event.target.value)!
        .participation!
    );

    setCurrentBoardName(
      boardRegistrations.find((board) => board.id === event.target.value)!.name
    );
  };

  const onBtnExport = useCallback(() => {
    const now: string = new Date().toLocaleString();
    gridRef.current!.api.exportDataAsCsv({
      fileName: `inscrits_tableau_${currentBoardName}_${now}`,
    });
  }, [currentBoardName]);

  return (
    <Stack spacing={2} sx={{ height: "calc(100vh - 100px)" }}>
      {loading ? ( // Afficher le loader si loading est true
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <FormControl fullWidth>
            <InputLabel id="selected-board-label">
              Sélectionnez un tableau
            </InputLabel>
            <Select
              labelId="selected-board-label"
              id="selected-board"
              value={currentBoardId}
              label="Sélectionner un tableau"
              onChange={handleChange}
            >
              {boardRegistrations.map((board: Tableau) => (
                <MenuItem key={board.id} value={board.id}>
                  {board.name} - {board.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Paper
            className="ag-theme-alpine"
            sx={{ width: "100%", flexGrow: 1, overflowX: "auto" }}
          >
            <AgGridReact
              domLayout="autoHeight"
              rowData={currentBoardRegistrations}
              columnDefs={columnDefs}
              ref={gridRef}
            ></AgGridReact>
          </Paper>
          <Button variant="outlined" onClick={onBtnExport}>
            Exporter sous excel
          </Button>{" "}
        </>
      )}
    </Stack>
  );
};

export default BoardRegistrations;
