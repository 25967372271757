import React, { FC, useEffect, useState, useRef, useCallback } from "react";

import {
  fetchPlayersWithRegistrations,
  getPlayerWithRegistrations,
  getPlayersWithRegistrationsLoading,
} from "../../../features/player/playerSlice";

import { useAppDispatch, useAppSelector } from "../../../store";

import { Button, Paper, Stack, CircularProgress, Grid } from "@mui/material";
import { Players } from "../../../types";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import { useLocation } from "react-router-dom";

interface RegistrationsProps {}

const Registrations: FC<RegistrationsProps> = () => {
  const playersWithRegistrations = useAppSelector(getPlayerWithRegistrations);
  const loading = useAppSelector(getPlayersWithRegistrationsLoading);

  const location = useLocation();

  const dispatch = useAppDispatch();
  const gridRef = useRef<AgGridReact<Players[]>>(null);

  const renderTableaux = (params: ValueGetterParams) => {
    let listeTableaux: string;
    listeTableaux = "";
    for (let i = 0; i < params.data.participation.length; i++) {
      listeTableaux += params.data.participation[i].tableau.name;
      if (i < params.data.participation.length - 1) {
        listeTableaux += " ";
      }
    }
    return listeTableaux;
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "Nom",
      sortable: true,
      flex: 2,
      valueGetter: (params) => params.data.name + " " + params.data.surname,
    },
    {
      headerName: "Points",
      sortable: true,
      field: "points",
      width: 80,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        // Convertir les valeurs de chaîne en nombres avant de les comparer
        const numA = parseInt(valueA, 10);
        const numB = parseInt(valueB, 10);

        if (numA === numB) {
          return 0;
        }
        if (numA < numB) {
          return -1;
        }
        return 1;
      },
    },
    {
      headerName: "Licence",
      sortable: true,
      field: "licenceId",
      width: 100,
    },
    {
      headerName: "Club",
      sortable: true,
      field: "club",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Categorie",
      sortable: true,
      field: "categorie",
      width: 100,
    },
    {
      headerName: "Sexe",
      sortable: true,
      field: "sexe",
      width: 80,
    },
    {
      headerName: "Email",
      sortable: true,
      field: "email",
      flex: 2,
    },
    {
      headerName: "Tableaux",
      sortable: true,
      valueGetter: renderTableaux,
      width: 100,
    },
  ]);

  useEffect(() => {
    dispatch(fetchPlayersWithRegistrations());
  }, [location.pathname, dispatch]);

  const onBtnExport = useCallback(() => {
    const now: string = new Date().toLocaleString();
    gridRef.current!.api.exportDataAsCsv({
      fileName: `inscriptions_${now}`,
    });
  }, []);

  return (
    <Stack spacing={2} sx={{ height: "calc(100vh - 100px)" }}>
      {loading ? ( // Afficher le loader si loading est true
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Paper
            className="ag-theme-alpine"
            sx={{ width: "100%", flexGrow: 1, overflowX: "auto" }}
          >
            <AgGridReact
              domLayout="autoHeight"
              rowData={playersWithRegistrations}
              columnDefs={columnDefs}
              ref={gridRef}
            ></AgGridReact>
          </Paper>
          <Button variant="outlined" onClick={onBtnExport}>
            Exporter sous excel
          </Button>
        </>
      )}
    </Stack>
  );
};

export default Registrations;
