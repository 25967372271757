import { BlockedMatchModalParams, Matchs, Players } from "../../types";

const checkIsMatchLaunchable = (
  matchToLaunch: Matchs,
  matchs: Matchs[]
): {
  isMatchLaunchable: boolean;
  blockedMatchModalParams: BlockedMatchModalParams;
} => {
  let isMatchLaunchable = true;
  let blockingPlayers: Array<{ player: Players; match: Matchs }> = [];

  matchs
    .filter((match: Matchs) => match.status === "ENCOURS")
    .forEach((currentMatch: Matchs) => {
      currentMatch.matchsPlayerParticipations.forEach((currentMatchPlayer) => {
        matchToLaunch.matchsPlayerParticipations.forEach(
          (matchToLaunchPlayer) => {
            if (
              matchToLaunchPlayer.playersId === currentMatchPlayer.playersId
            ) {
              isMatchLaunchable = false;
              blockingPlayers.push({
                player: matchToLaunchPlayer.players!,
                match: currentMatch,
              });
            }
          }
        );
      });
    });

  return { isMatchLaunchable, blockedMatchModalParams: { blockingPlayers } };
};

export default checkIsMatchLaunchable;
