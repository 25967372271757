import React, { FC, useEffect, useRef, useState } from "react";

import { Alert, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  fetchMatchs,
  getPlayersWithCurrentMatchs,
} from "../../features/matchs/matchsSlice";
import { Players } from "../../types";
import {
  ColDef,
  Grid,
  GridOptions,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import "./styles.css";

interface LivePlayerProps {}

const LivePlayer: FC<LivePlayerProps> = () => {
  const dispatch = useAppDispatch();

  const playersWithCurrentMatchs: Players[] = useAppSelector(
    getPlayersWithCurrentMatchs
  );

  const gridRef = useRef<AgGridReact<Players[]>>(null);

  const calculateDureeMatch = (params: ValueGetterParams) => {
    const now: any = new Date();
    const lancement: any =
      params.data!.matchPlayerParticipation![0].match?.dateLancement;
    const duration = Math.floor((now - lancement) / 1000 / 60);

    return duration + " min";
  };

  useEffect(() => {
    if (gridRef.current! && gridRef.current!.api) {
      gridRef.current!.api.sizeColumnsToFit();
    }
  }, [playersWithCurrentMatchs]);

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "Joueur",
      width: 150,
      valueGetter: (params) => params.data.name + " " + params.data.surname,
    },
    {
      headerName: "Table",
      width: 70,
      valueGetter: (params: ValueGetterParams<Players>) =>
        params.data?.matchPlayerParticipation![0].match?.tableId,
    },

    {
      headerName: "Heure",
      width: 90,
      valueGetter: (params: ValueGetterParams<Players>) => {
        return params.data?.matchPlayerParticipation![0].match?.dateLancement
          ? params.data?.matchPlayerParticipation![0].match?.dateLancement.toLocaleTimeString(
              "fr-FR"
            )
          : "";
      },
    },
    {
      headerName: "Durée",
      valueGetter: (params: ValueGetterParams) => calculateDureeMatch(params),
      width: 80,
    },
  ]);

  useEffect(() => {
    dispatch(fetchMatchs());
  }, [dispatch]);

  return (
    <Stack spacing={2} alignItems={"center"}>
      <Alert severity="info">
        <Typography>
          Veuillez trouver ici la liste des joueurs ayant un match en cours
        </Typography>
      </Alert>
      <div
        className="ag-theme-alpine live-player-grid"
        style={{
          width: "100%",
          maxWidth: "1400px",
          overflowX: "auto",
        }}
      >
        <AgGridReact
          domLayout="autoHeight"
          rowData={playersWithCurrentMatchs}
          columnDefs={columnDefs}
          animateRows={true}
          ref={gridRef}
          alwaysShowHorizontalScroll={true}
        ></AgGridReact>
      </div>
    </Stack>
  );
};

export default LivePlayer;
