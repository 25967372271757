import React, { FC, useState } from "react";

import { Typography, Stack, Snackbar, Alert, Switch } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../store";
import {
  changeClosedTournamentLabel,
  changeTournamentBoardClosedLabel,
  changeTournamentBoardClosedLabelWithQueue,
  changeTournamentRegistrationStatus,
  getTournoi,
} from "../../../features/tournoi/tournoiSlice";

import EditableField from "../../Base/EditableField";

interface AdminTournamentProps {}

const AdminTournament: FC<AdminTournamentProps> = () => {
  const tournoi = useAppSelector(getTournoi);
  const dispatch = useAppDispatch();
  const [snackBarOpened, setSnackBarOpened] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const openSnackBar = (message: string) => {
    setSnackBarMessage(message);
    setSnackBarOpened(true);
  };

  const handleCloseSnackBar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpened(false);
  };

  return (
    <>
      <Stack direction="column" spacing={3} margin={2}>
        <Stack direction="row" spacing={3}>
          <Typography>Inscriptions ouvertes :</Typography>
          <Switch
            size="small"
            checked={!tournoi?.isRegisterClosed}
            onChange={async (event) => {
              const resStatusAction = await dispatch(
                changeTournamentRegistrationStatus({
                  tournamentId: tournoi!.id,
                  isRegisterClosed: !event.target.checked,
                })
              );

              if (
                changeTournamentRegistrationStatus.fulfilled.match(
                  resStatusAction
                )
              ) {
                openSnackBar(
                  !event.target.checked
                    ? `Inscriptions au tournoi ouvertes`
                    : `Inscriptions au tournoi fermées`
                );
              }
            }}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <Typography>
            Libellé affiché lorsque les inscriptions au tournoi sont fermées :
          </Typography>
          <EditableField
            label={tournoi?.closedTournamentLabel!}
            type="string"
            onChange={async (newValue) => {
              const resAction = await dispatch(
                changeClosedTournamentLabel({
                  tournamentId: tournoi?.id,
                  closedTournamentLabel: newValue,
                })
              );

              if (changeTournamentBoardClosedLabel.fulfilled.match(resAction)) {
                openSnackBar(
                  "Libellé affiché lorsqu'un tableau est fermé mis à jour"
                );
              }
            }}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <Typography>Libellé affiché lorsqu'un tableau est fermé :</Typography>
          <EditableField
            label={tournoi?.boardClosedDefaultLabel!}
            type="string"
            onChange={async (newValue) => {
              const resAction = await dispatch(
                changeTournamentBoardClosedLabel({
                  tournamentId: tournoi?.id,
                  boardClosedDefaultLabel: newValue,
                })
              );

              if (changeTournamentBoardClosedLabel.fulfilled.match(resAction)) {
                openSnackBar(
                  "Libellé affiché lorsqu'un tableau est fermé mis à jour"
                );
              }
            }}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <Typography>
            Libellé affiché lorsqu'il y a une file d'attente :
          </Typography>
          <EditableField
            label={tournoi?.boardClosedWithQueueLabel!}
            type="string"
            onChange={async (newValue) => {
              const resAction = await dispatch(
                changeTournamentBoardClosedLabelWithQueue({
                  tournamentId: tournoi?.id,
                  boardClosedWithQueueLabel: newValue,
                })
              );

              if (
                changeTournamentBoardClosedLabelWithQueue.fulfilled.match(
                  resAction
                )
              ) {
                openSnackBar(
                  "Libellé affiché lorsqu'un tableau est fermé et qu'il a une file d'attente mis à jour "
                );
              }
            }}
          />
        </Stack>
      </Stack>

      <Snackbar
        open={snackBarOpened}
        autoHideDuration={6000}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AdminTournament;
