import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
} from "firebase/firestore";

import app from "./firebaseApp";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

// Initialize Authentication
const auth = getAuth(app);

// Initialize Database Module
const db = getFirestore(app);

// Create a user using email
const createUserEmailPassword = async (email: string, password: string) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

// Sign in via email/password
const loginEmailPassword = async (email: string, password: string) => {
  const res = await signInWithEmailAndPassword(auth, email, password);
  return res;
};

// Send password reset link to an email address
const sendPassResetEmail = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    if (err instanceof Error) {
      console.error(err);
      alert(err.message);
    }
  }
};

// Logout.  Firebase will know what user is logged in through the auth variable.
const logout = async () => {
  try {
    await auth.signOut();
  } catch (err) {
    console.error(err);
  }
};

// Check if user email already exists
const userEmailExists = async (email: string) => {
  const q = query(collection(db, "users"), where("email", "==", email));
  const docs = await getDocs(q);
  if (docs.docs.length > 0) {
    return true;
  } else {
    return false;
  }
};

export {
  auth,
  createUserEmailPassword,
  loginEmailPassword,
  sendPassResetEmail,
  logout,
  userEmailExists,
};
