import {
  Card,
  Grid,
  Button,
  List,
  Box,
  CardContent,
  CardActions,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Alert,
  Tooltip,
} from "@mui/material";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../store";
import { fetchedPlayer, updateMail } from "../../features/player/playerSlice";
import {
  Badge,
  Class,
  Groups,
  PermIdentity,
  Stars,
  Wc,
} from "@mui/icons-material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebaseAuth";

interface RegisterLicenceValidationProps {}

const RegisterLicenceValidation: FC<RegisterLicenceValidationProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const player = useAppSelector(fetchedPlayer);

  const handleValidate = async () => {
    await dispatch(updateMail(user?.email!));
    navigate("/register/select");
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Box display="inline-block">
          <Alert severity="info">
            Veuillez vérifier que les informations correspondent bien au joueur
            que vous voulez inscrire
          </Alert>
        </Box>
      </Grid>
      <Grid item>
        <Box display="inline-block">
          <Card elevation={3}>
            <CardContent sx={{ minWidth: 300 }}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Tooltip title="Nom" placement="top-end" arrow>
                      <Avatar sx={{ bgcolor: "primary.light" }}>
                        <PermIdentity />
                      </Avatar>
                    </Tooltip>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${player!.name} ${player!.surname}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Tooltip
                      title="Numéro de licence"
                      placement="top-end"
                      arrow
                    >
                      <Avatar sx={{ bgcolor: "primary.light" }}>
                        <Badge />
                      </Avatar>
                    </Tooltip>
                  </ListItemAvatar>
                  <ListItemText primary={player!.licenceId} />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Tooltip title="Club" placement="top-end" arrow>
                      <Avatar sx={{ bgcolor: "primary.light" }}>
                        <Groups />
                      </Avatar>
                    </Tooltip>
                  </ListItemAvatar>
                  <ListItemText primary={player!.club} />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Tooltip
                      title="Points sur la licence"
                      placement="top-end"
                      arrow
                    >
                      <Avatar sx={{ bgcolor: "primary.light" }}>
                        <Stars />
                      </Avatar>
                    </Tooltip>
                  </ListItemAvatar>
                  <ListItemText primary={`${player!.points} Points`} />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Tooltip title="Genre" placement="top-end" arrow>
                      <Avatar sx={{ bgcolor: "primary.light" }}>
                        <Wc />
                      </Avatar>
                    </Tooltip>
                  </ListItemAvatar>
                  <ListItemText
                    primary={player!.sexe === "M" ? "Homme" : "Femme"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Tooltip title="Catégorie d'age" placement="top-end" arrow>
                      <Avatar sx={{ bgcolor: "primary.light" }}>
                        <Class />
                      </Avatar>
                    </Tooltip>
                  </ListItemAvatar>
                  <ListItemText primary={player!.categorie} />
                </ListItem>
              </List>
            </CardContent>
            <CardActions>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{ paddingBottom: "16px" }}
              >
                <Button variant="contained" onClick={handleValidate}>
                  Il s'agit bien de moi
                </Button>
              </Grid>
            </CardActions>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RegisterLicenceValidation;
