import React, { FC } from "react";
import { Typography, Paper, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import { AccessibleBoard } from "../../features/player/playerSlice";

import { RegisterSelectBoard } from "./RegisterSelectBoard";

interface RegisterListBoardsDateProps {
  date: string;
  accessibleBoards: AccessibleBoard[];
}

export const RegisterListBoardsDate: FC<RegisterListBoardsDateProps> = (
  props: RegisterListBoardsDateProps
) => (
  <Stack spacing={1} width="100%">
    <Paper
      sx={{
        padding: 2,
        textAlign: "center",
        display: "inline-block",
        backgroundColor: "primary.dark",
        margin: "auto",
        borderRadius: 4,
      }}
      elevation={2}
    >
      <Typography color={"primary.contrastText"}>
        {new Date(props.date).toLocaleDateString("fr-FR", {
          dateStyle: "full",
        })}
      </Typography>
    </Paper>
    <Grid2 container spacing={2}>
      {props.accessibleBoards.map((b) => (
        <RegisterSelectBoard key={b.id} board={b} />
      ))}
    </Grid2>
  </Stack>
);
