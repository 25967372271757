import { Box } from "@mui/material";
import React, { FC } from "react";

import { MatchsPlayerParticipation } from "../../types/MatchsPlayerParticipation";

type Props = {
  matchPlayers: Array<MatchsPlayerParticipation>;
};

const TablePlayers: FC<Props> = ({ matchPlayers }) => {
  return (
    <Box
      sx={{
        "&>*:nth-child(1)": { top: "-20px" },
        "&>*:nth-child(2)": { bottom: "-20px" },
        "&>*:nth-child(3)": { top: "36px" },
        "&>*:nth-child(4)": { bottom: "36px" },
      }}
    >
      {matchPlayers.map((mp) => (
        <Box
          key={mp.id}
          sx={{
            width: "90%",
            padding: "5px 10px",
            background: "#efefef",
            border: "#fff 4px solid;",
            boxShadow: "#000 0 0 5px",
            position: "absolute",
            zIndex: 10,
            textAlign: "center",
            left: "50%",
            transform: "translateX(-50%)",
            borderRadius: "2px",
            fontSize: "12px",
            fontWeight: "bold",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {mp.players!.name} {mp.players!.surname}
        </Box>
      ))}
    </Box>
  );
};

export default TablePlayers;
