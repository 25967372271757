import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { FC, useEffect } from "react";

import { fetchBoards, getBoards } from "../../../features/tournoi/tournoiSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Tableau } from "../../../types";

type Props = {
  value: string;
  handleBoardChange: (boardId: string) => void;
};

const BoardSelector: FC<Props> = ({ value, handleBoardChange }) => {
  const dispatch = useAppDispatch();
  const boards = useAppSelector(getBoards);

  useEffect(() => {
    dispatch(fetchBoards());
  }, [dispatch]);

  useEffect(() => {
    if (boards.length === 0 || value !== "") {
      return;
    }
    handleBoardChange(boards[0].id);
  }, [boards, handleBoardChange, value]);

  return (
    <FormControl>
      <InputLabel id="selected-board-label">Sélectionnez un tableau</InputLabel>
      <Select
        labelId="selected-board-label"
        id="selected-board"
        value={value}
        label="Sélectionner un tableau"
        onChange={(event: SelectChangeEvent) => {
          handleBoardChange(event.target.value);
        }}
      >
        {boards.map((board: Tableau) => (
          <MenuItem key={board.id} value={board.id}>
            {board.name} - {board.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BoardSelector;
