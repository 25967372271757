import React, { FC, useEffect, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Stack,
  Switch,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useAppDispatch, useAppSelector } from "../../../store";
import {
  getBoardRegistrations,
  fetchBoardRegistrations,
  getTournoi,
  updateNbPlaces,
  changeBoardStatus,
  changeBoardQueueStatus,
  updateRealNbPlaces,
} from "../../../features/tournoi/tournoiSlice";
import { Tableau } from "../../../types";
import EditableField from "../../Base/EditableField";

interface AdminBoardsProps {}

const AdminBoards: FC<AdminBoardsProps> = () => {
  const boards = useAppSelector(getBoardRegistrations);
  const tournoi = useAppSelector(getTournoi);
  const dispatch = useAppDispatch();
  const [snackBarOpened, setSnackBarOpened] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  useEffect(() => {
    dispatch(fetchBoardRegistrations());
  }, [dispatch, tournoi]);

  const openSnackBar = (message: string) => {
    setSnackBarMessage(message);
    setSnackBarOpened(true);
  };

  const handleCloseSnackBar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpened(false);
  };

  const generateAccordionColor = (board: Tableau) => {
    if (board.ferme || board.isFull) {
      return "grey.500";
    }
    if (board.nbParticipants! >= board.realNbPlaces) {
      return "error.light";
    }

    if (board.realNbPlaces - board.nbParticipants! < 6) {
      return "warning.light";
    }
    return "primary.light";
  };

  return (
    <Stack>
      {boards.map((board: Tableau) => (
        <Accordion key={board.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              {board.name} - {board.label}
              <Box
                sx={{
                  display: "inline",
                  backgroundColor: generateAccordionColor(board),
                  color: "grey.50",
                  padding: "4px",
                  borderRadius: 10,
                  marginLeft: "6px",
                }}
              >
                {board.participation?.length}/{board.realNbPlaces}
              </Box>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="column" spacing={3} margin={2}>
              <Stack direction="row" spacing={3}>
                <Typography>Nombre de places :</Typography>
                <EditableField
                  label={board.nbPlace}
                  type="number"
                  onChange={async (newValue) => {
                    const resAction = await dispatch(
                      updateNbPlaces({ boardId: board.id, nbPlace: newValue })
                    );

                    if (updateNbPlaces.fulfilled.match(resAction)) {
                      openSnackBar(
                        `Nombre de places du tableau ${board.name} mis à jour`
                      );
                    }
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={3}>
                <Typography>Nombre de places réelles :</Typography>
                <EditableField
                  label={board.realNbPlaces.toString()}
                  type="number"
                  onChange={async (newValue) => {
                    const resAction = await dispatch(
                      updateRealNbPlaces({
                        boardId: board.id,
                        realNbPlaces: parseInt(newValue),
                      })
                    );

                    if (updateRealNbPlaces.fulfilled.match(resAction)) {
                      openSnackBar(
                        `Nombre de places du tableau ${board.name} mis à jour`
                      );
                    }
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={3} margin={2}>
                <Typography>Tableau ouvert :</Typography>
                <Switch
                  size="small"
                  checked={!board.ferme}
                  onChange={async (event) => {
                    const resStatusAction = await dispatch(
                      changeBoardStatus({
                        boardId: board.id,
                        closed: !event.target.checked,
                      })
                    );

                    if (changeBoardStatus.fulfilled.match(resStatusAction)) {
                      openSnackBar(
                        event.target.checked
                          ? `Tableau ${board.name} ouvert`
                          : `Tableau ${board.name} fermé`
                      );
                    }
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={3} margin={2}>
                <Typography>Tableau avec file d'attente :</Typography>
                <Switch
                  size="small"
                  checked={board.hasWaitingQueue}
                  onChange={async (event) => {
                    const resStatusAction = await dispatch(
                      changeBoardQueueStatus({
                        boardId: board.id,
                        hasWaitingQueue: event.target.checked,
                      })
                    );

                    if (
                      changeBoardQueueStatus.fulfilled.match(resStatusAction)
                    ) {
                      openSnackBar(
                        event.target.checked
                          ? `File d'attente du tableau ${board.name} ouverte`
                          : `File d'attente du tableau ${board.name} fermée`
                      );
                    }
                  }}
                />
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}
      <Snackbar
        open={snackBarOpened}
        autoHideDuration={6000}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default AdminBoards;
