import {
  TextField,
  Grid,
  Button,
  Alert,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";

import React, { FC, useState } from "react";
import isEmpty from "validator/lib/isEmpty";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  fetchPlayerFromLicence,
  fetchedErrorMessage,
  fetchedNoPlayer,
  fetchedSearchError,
  getPlayerLoading,
} from "../../features/player/playerSlice";
import {
  getAreOpenedRegistrations,
  getTournoi,
} from "../../features/tournoi/tournoiSlice";

interface RegisterProps {}

const Register: FC<RegisterProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [licenceId, setLicenceId] = useState<string>("");
  const [isLicenceValid, setIsLicenceValid] = useState<boolean>(false);
  const [isLicenceDirty, setIsLicenceDirty] = useState<boolean>(false);

  const noPlayer = useAppSelector(fetchedNoPlayer);
  const errorMessage: string = useAppSelector(fetchedErrorMessage);
  const searchError = useAppSelector(fetchedSearchError);
  const areOpenedRegistrations = useAppSelector(getAreOpenedRegistrations);
  const tournoi = useAppSelector(getTournoi);
  const loading = useAppSelector(getPlayerLoading);

  const handleLicenceChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const licenceIdValue: string = e.target.value;
    setLicenceId(licenceIdValue);
    setIsLicenceValid(!isEmpty(licenceIdValue));
  };

  if (!areOpenedRegistrations) {
    return (
      <Alert severity="info">
        <Typography variant="h6">{tournoi?.closedTournamentLabel}</Typography>
      </Alert>
    );
  }

  return (
    <Grid container direction={"column"} spacing={5}>
      <Grid item>
        <Alert severity="info">
          Afin de pouvoir vous inscrire au tournoi, merci de saisir votre numéro
          de licence.
        </Alert>
      </Grid>

      <Grid item>
        <TextField
          type="text"
          label="Numéro de Licence"
          autoFocus={true}
          required={true}
          fullWidth
          value={licenceId}
          onChange={handleLicenceChange}
          onBlur={() => setIsLicenceDirty(true)}
          helperText={errorMessage}
          error={(isLicenceDirty && !isLicenceValid) || noPlayer}
        />
      </Grid>

      <Grid item>
        <Button
          variant="contained"
          disabled={!isLicenceValid || loading}
          onClick={async () => {
            const res = await dispatch(
              fetchPlayerFromLicence(licenceId)
            ).unwrap();

            if (res) {
              navigate("/register/licence-validation");
            }
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            Confirmer les informations
            {loading && (
              <CircularProgress color="info" size={24} sx={{ marginLeft: 1 }} />
            )}
          </Box>
        </Button>
      </Grid>
      <Grid item>
        {searchError && (
          <Alert severity="error">
            Erreur lors de la recherche de votre licence. Réessayez plus tard
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};

export default Register;
