import { Box } from "@mui/material";
import React, { FC } from "react";

type Props = { children: JSX.Element; tableId: number };

const TennisTable: FC<Props> = ({ tableId, children }) => {
  return (
    <Box>
      <Box
        sx={{
          background:
            "linear-gradient(135deg, rgba(30, 87, 153, 1) 0%, rgba(25, 140, 229, 1) 100%)",
          width: "125px",
          height: "197px",
          borderRadius: "2px",
          borderColor: "#fff",
          borderWidth: "4px",
          borderStyle: "solid",
          boxShadow: "#000 0 0 1px",
          position: "relative",
          "&::before": {
            content: '""',
            background: "#fff",
            width: "1px",
            height: "197px",
            display: "block",
            position: "absolute",
            right: "50%",
            top: 0,
            transform: "translateX(50%)",
          },
          "&::after": {
            content: '""',
            background: "#fff",
            width: "125px",
            height: "3px",
            display: "block",
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
          },
        }}
      >
        <Box
          sx={{
            width: "30%",
            color: "#FF0000",
            background: "#ffb3b3",
            border: "#FF0000 2px solid",
            boxShadow: "#000 0 0 5px",
            position: "absolute",
            zIndex: 10,
            textAlign: "center",
            left: "-15%",
            transform: "translateX(-50%)",
            borderRadius: "100px",
            fontSize: "16px",
            top: "45%",
          }}
        >
          {tableId}
        </Box>
        {children}
      </Box>
    </Box>
  );
};

export default TennisTable;
