import {
  ColDef,
  ICellRendererParams,
  RowClassParams,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import {
  getMatchs,
  changeMatchStatus,
  getBlockedMatchModalParams,
  closeBlockedMatchModal,
  changeMatchTable,
  closeOccupiedTableModal,
  getOccupiedTableModalParams,
  closeFreedMatchModal,
  getFreedMatchModalParams,
} from "../../../features/matchs/matchsSlice";
import { useAppSelector, useAppDispatch } from "../../../store";
import { Matchs } from "../../../types";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";

type Props = {};

const MatchsList: FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const matchs = useAppSelector(getMatchs);
  const blockedMatchModalParams = useAppSelector(getBlockedMatchModalParams);
  const occupiedTableModalParams = useAppSelector(getOccupiedTableModalParams);
  const freedMatchModalParams = useAppSelector(getFreedMatchModalParams);

  const statusButtonRenderer = (params: ICellRendererParams<any, Matchs>) => {
    const currentMatch: Matchs = params.data;
    const statusButtonLabel =
      currentMatch.status === "ENCOURS" ? "TERMINER" : "LANCER";
    return (
      <Box display="flex" justifyContent="center">
        <Button
          onClick={() => {
            dispatch(changeMatchStatus(currentMatch));
          }}
        >
          {statusButtonLabel}
        </Button>
      </Box>
    );
  };

  const getRowStyle = (params: RowClassParams<Matchs>) => {
    let color;
    switch (params.data?.status) {
      case "DOUBLON":
        color = { background: "#ffccff" };
        break;
      case "LIBERE":
        color = { background: "#b3f0ff" };
        break;
      case "ENCOURS":
        color = { background: "#b3ffb3" };
        break;
      default:
        color = { background: "white" };
    }
    return color;
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "Tableau",
      field: "tableau.name",
      width: 80,
    },
    {
      headerName: "Tour",
      field: "tour.label",
      width: 80,
    },
    {
      headerName: "Joueur 1",
      valueGetter: (params: ValueGetterParams) =>
        getPlayerNameFromMatchData(params, 0),
      width: 160,
    },
    {
      headerName: "Joueur 2",
      valueGetter: (params: ValueGetterParams) =>
        getPlayerNameFromMatchData(params, 1),
      width: 160,
    },
    {
      headerName: "Joueur 3",
      valueGetter: (params: ValueGetterParams) =>
        getPlayerNameFromMatchData(params, 2),
      width: 160,
    },
    {
      headerName: "Joueur 4",
      valueGetter: (params: ValueGetterParams) =>
        getPlayerNameFromMatchData(params, 3),
      width: 160,
    },
    {
      headerName: "Création",
      valueGetter: (params: ValueGetterParams<Matchs>) => {
        return params.data?.dateCreation
          ? params.data?.dateCreation.toLocaleTimeString("fr-FR")
          : "";
      },
      width: 110,
    },
    {
      headerName: "Lancement",
      valueGetter: (params: ValueGetterParams<Matchs>) => {
        return params.data?.dateLancement
          ? params.data?.dateLancement.toLocaleTimeString("fr-FR")
          : "";
      },
      width: 110,
    },
    {
      headerName: "Durée",
      valueGetter: (params: ValueGetterParams) => calculateDureeMatch(params),
      width: 80,
    },
    {
      headerName: "Statut",
      field: "status",
      width: 120,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Table",
      field: "tableId",
      editable: true,
      singleClickEdit: true,
      valueSetter: (params) => {
        dispatch(
          changeMatchTable({
            matchId: params.data.id,
            tableId: parseInt(params.newValue),
          })
        );
        return true;
      },
      width: 80,
    },
    {
      headerName: "Action",
      cellRenderer: statusButtonRenderer,
      width: 80,
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    []
  );

  const gridRef = useRef<AgGridReact<Matchs[]>>(null);

  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current?.api.sizeColumnsToFit();
    }
  }, [matchs]);

  if (gridRef.current?.api) {
    gridRef.current?.api.setGetRowStyle(getRowStyle);
  }

  const getPlayerNameFromMatchData = (
    params: ValueGetterParams,
    playerIndex: number
  ) => {
    if (!params.data.matchsPlayerParticipations[playerIndex]) return "";
    return (
      params.data.matchsPlayerParticipations[playerIndex].players.name +
      " " +
      params.data.matchsPlayerParticipations[playerIndex].players.surname
    );
  };

  const calculateDureeMatch = (params: ValueGetterParams) => {
    if (params.data.status !== "ENCOURS") {
      return "";
    }
    const now: any = new Date();
    const lancement: any = params.data.dateLancement;
    const duration = Math.floor((now - lancement) / 1000 / 60);

    return duration + " min";
  };

  return (
    <>
      <Paper
        className="ag-theme-alpine"
        sx={{ width: "100%", height: "500px", overflowX: "auto" }}
      >
        <AgGridReact
          domLayout="autoHeight"
          rowData={matchs}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          ref={gridRef}
        ></AgGridReact>
      </Paper>
      <Dialog
        onClose={() => {
          dispatch(closeBlockedMatchModal());
        }}
        open={blockedMatchModalParams !== null}
      >
        <DialogTitle>Attention doublon !!!</DialogTitle>
        <List>
          {blockedMatchModalParams?.blockingPlayers.map((p, idx: number) => {
            let text = `Le joueur ${p.player.surname} ${p.player.name} a déjà un match en cours `;
            if (p.match.table) {
              text += `sur la table ${p.match.table}`;
            }
            return (
              <ListItem key={idx}>
                <ListItemText primary={text} />
              </ListItem>
            );
          })}
        </List>
      </Dialog>
      <Dialog
        onClose={() => {
          dispatch(closeOccupiedTableModal());
        }}
        open={occupiedTableModalParams !== null}
      >
        <DialogTitle>Table occupée</DialogTitle>
        <Box sx={{ padding: "16px" }}>
          <Typography>
            La table {occupiedTableModalParams?.blockedTable} est déjà occupée
            par au moins un autre match. Mettez fin à celui avant d'attribuer
            cette table.
          </Typography>
        </Box>
      </Dialog>
      <Dialog
        onClose={() => {
          dispatch(closeFreedMatchModal());
        }}
        open={freedMatchModalParams !== null}
      >
        <DialogTitle>Joueurs libres</DialogTitle>
        <Box sx={{ padding: "16px" }}>
          <Typography>
            La fin de ce match libére les matchs en attente pour les joueurs
            suivants :
          </Typography>
          <List>
            {freedMatchModalParams?.freedPlayers.map((p, idx: number) => {
              return (
                <ListItem key={idx}>
                  <ListItemText primary={`${p.surname} ${p.name}`} />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Dialog>
    </>
  );
};

export default MatchsList;
