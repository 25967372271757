import React, { FC } from "react";
import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Stack,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import {
  AccessibleBoard,
  switchState,
} from "../../features/player/playerSlice";
import { getTournoi } from "../../features/tournoi/tournoiSlice";

import { useAppDispatch, useAppSelector } from "../../store";

interface RegisterSelectBoardProps {
  board: AccessibleBoard;
}

export const RegisterSelectBoard: FC<RegisterSelectBoardProps> = (
  props: RegisterSelectBoardProps
) => {
  const dispatch = useAppDispatch();
  const tournoi = useAppSelector(getTournoi);
  const { board } = props;

  const titleTournementClosed = board.hasWaitingQueue
    ? tournoi?.boardClosedWithQueueLabel
    : tournoi?.boardClosedDefaultLabel;

  return (
    <Grid2 xs={12} sm={6} md={4}>
      <Card
        elevation={8}
        sx={{
          backgroundColor: board.selected ? "primary.main" : "transparent",
          color: board.selected ? "primary.contrastText" : "text.primary",
          "& .Mui-disabled": {
            backgroundColor: board.selected ? "primary.main" : "grey.500",
          },
        }}
      >
        <CardActionArea
          onClick={() => {
            dispatch(switchState(board.id));
          }}
          disabled={board.disabled}
        >
          <CardContent sx={{ padding: 2 }}>
            <Stack>
              <Typography variant="h6" paddingBottom={1}>
                Tableau {board.name}
              </Typography>

              {(board.ferme || board.isFull) && (
                <Stack direction={"row"} spacing={1}>
                  <Typography color={"error"}>
                    {titleTournementClosed}
                  </Typography>
                </Stack>
              )}
              <Typography variant="body1">Tableau {board.label}</Typography>
              <Typography variant="body1">
                Heure limite de pointage : {board.heureLimite}
              </Typography>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid2>
  );
};
