import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { RootState, AppDispatch } from "../../store";
import { Participation, Tableau, Tour, Tournoi } from "../../types";
import firestoreInstance from "../../services/firestoreInstance";
import cloudFunctions from "../../services/firebaseCloudFunctions";
import { httpsCallable } from "firebase/functions";

export type TournoiState = {
  tournoi: Tournoi | null;
  boards: Tableau[];
  boardRegistrations: Tableau[];
  boardRegistrationsLoading: boolean;
  rounds: Tour[];
  allRegistrations: Participation[];
  allRegistrationsLoading: boolean;
};

const initialState: TournoiState = {
  tournoi: null,
  boards: [],
  boardRegistrations: [],
  boardRegistrationsLoading: false,
  rounds: [],
  allRegistrations: [],
  allRegistrationsLoading: false,
};

// migré
export const fetchTournoi = createAsyncThunk(
  "tournoi/fetchTournoi",
  async () => {
    const querySnapshot = await getDocs(
      query(
        collection(firestoreInstance, "tournois"),
        where("current", "==", true)
      )
    );
    const doc = querySnapshot.docs[0];

    return { ...(doc.data() as Tournoi), ...{ id: doc.id } };
  }
);

export const fetchBoards = createAsyncThunk<
  Tableau[],
  void,
  { state: RootState }
>("tournoi/fetchBoards", async (_, thunkApi) => {
  const currentTournamentId = thunkApi.getState().tournoi.tournoi?.id;

  const getTableaux = httpsCallable<unknown, any>(
    cloudFunctions,
    "getTableaux"
  );

  return (await getTableaux({ tournamentId: currentTournamentId }))
    .data as Tableau[];
});

export const fetchRounds = createAsyncThunk<Tour[], void, { state: RootState }>(
  "tournoi/fetchRounds",
  async (_, thunkApi) => {
    const tours = (
      await getDocs(query(collection(firestoreInstance, "tour")))
    ).docs.map((doc) => ({ ...(doc.data() as Tour), ...{ id: doc.id } }));
    return tours;
  }
);

export const fetchBoardRegistrations = createAsyncThunk<
  Tableau[],
  void,
  { state: RootState }
>("tournoi/fetchBoardRegistrations", async (_, thunkApi) => {
  const currentTournamentId: string = thunkApi.getState().tournoi.tournoi?.id;

  const getBoardRegistrations = httpsCallable<unknown, Tableau[]>(
    cloudFunctions,
    "getBoardRegistrations"
  );

  const response = await getBoardRegistrations({
    tournamentId: currentTournamentId,
  });

  return response.data;
});

export const fetchAllRegistrations = createAsyncThunk<
  Participation[],
  { reload: boolean } | undefined,
  { state: RootState }
>("tournoi/fetchAllRegistrations", async (_, thunkApi) => {
  const currentTournamentId: string = thunkApi.getState().tournoi.tournoi?.id;

  const getAllRegistrations = httpsCallable<unknown, Participation[]>(
    cloudFunctions,
    "getAllRegistrations"
  );

  const response = await getAllRegistrations({
    tournamentId: currentTournamentId,
  });

  return response.data;
});

export const updateNbPlaces = createAsyncThunk<
  void,
  { boardId: string; nbPlace: string },
  { state: RootState }
>(
  "tournoi/updateBoardNbPlaces",
  async ({ boardId, nbPlace }, { dispatch, getState }) => {
    const currentTournamentId: string = getState().tournoi.tournoi?.id;
    const tournamentRef = doc(
      firestoreInstance,
      "tournois",
      currentTournamentId,
      "tableaux",
      boardId
    );

    await updateDoc(tournamentRef, { nbPlace });
    await dispatch(fetchBoardRegistrations());
    return;
  }
);

export const updateRealNbPlaces = createAsyncThunk<
  void,
  { boardId: string; realNbPlaces: number },
  { state: RootState }
>(
  "tournoi/updateRealNbPlaces",
  async ({ boardId, realNbPlaces }, { dispatch, getState }) => {
    const currentTournamentId: string = getState().tournoi.tournoi?.id;
    const tournamentRef = doc(
      firestoreInstance,
      "tournois",
      currentTournamentId,
      "tableaux",
      boardId
    );

    await updateDoc(tournamentRef, { realNbPlaces });
    await dispatch(fetchBoardRegistrations());
    return;
  }
);

export const changeBoardStatus = createAsyncThunk<
  void,
  { boardId: string; closed: boolean },
  { state: RootState }
>(
  "tournoi/changeBoardStatus",
  async ({ boardId, closed }, { dispatch, getState }) => {
    const currentTournamentId: string = getState().tournoi.tournoi?.id;
    const tournamentRef = doc(
      firestoreInstance,
      "tournois",
      currentTournamentId,
      "tableaux",
      boardId
    );

    await updateDoc(tournamentRef, { ferme: closed });
    await dispatch(fetchBoardRegistrations());
    return;
  }
);

export const changeBoardQueueStatus = createAsyncThunk<
  void,
  { boardId: string; hasWaitingQueue: boolean },
  { state: RootState }
>(
  "tournoi/changeBoardQueueStatus",
  async ({ boardId, hasWaitingQueue }, { dispatch, getState }) => {
    const currentTournamentId: string = getState().tournoi.tournoi?.id;
    const tournamentRef = doc(
      firestoreInstance,
      "tournois",
      currentTournamentId,
      "tableaux",
      boardId
    );

    await updateDoc(tournamentRef, { hasWaitingQueue });
    await dispatch(fetchBoardRegistrations());
    return;
  }
);

export const changeTournamentRegistrationStatus = createAsyncThunk<
  void,
  { tournamentId: string; isRegisterClosed: boolean },
  { dispatch: AppDispatch }
>(
  "tournoi/changeTournamentRegistrationStatus",
  async ({ tournamentId, isRegisterClosed }, { dispatch }) => {
    //mettre à jour le champs isRegisterClosed du document de la collection firestore tournois dont l'id vaut tournamentId

    const tournamentRef = doc(firestoreInstance, "tournois", tournamentId);

    await updateDoc(tournamentRef, { isRegisterClosed });

    await dispatch(fetchTournoi());

    return;
  }
);

export const changeTournamentBoardClosedLabel = createAsyncThunk<
  void,
  { tournamentId: string; boardClosedDefaultLabel: string },
  { dispatch: AppDispatch }
>(
  "tournoi/changeTournamentBoardClosedLabel",
  async ({ tournamentId, boardClosedDefaultLabel }, { dispatch }) => {
    const tournamentRef = doc(firestoreInstance, "tournois", tournamentId);

    await updateDoc(tournamentRef, { boardClosedDefaultLabel });

    await dispatch(fetchTournoi());

    return;
  }
);

export const changeTournamentBoardClosedLabelWithQueue = createAsyncThunk<
  void,
  { tournamentId: string; boardClosedWithQueueLabel: string },
  { dispatch: AppDispatch }
>(
  "tournoi/changeTournamentBoardClosedLabelWithQueue",
  async ({ tournamentId, boardClosedWithQueueLabel }, { dispatch }) => {
    const tournamentRef = doc(firestoreInstance, "tournois", tournamentId);

    await updateDoc(tournamentRef, { boardClosedWithQueueLabel });

    await dispatch(fetchTournoi());

    return;
  }
);

export const changeClosedTournamentLabel = createAsyncThunk<
  void,
  { tournamentId: string; closedTournamentLabel: string },
  { dispatch: AppDispatch }
>(
  "tournoi/closedTournamentLabel",
  async ({ tournamentId, closedTournamentLabel }, { dispatch }) => {
    const tournamentRef = doc(firestoreInstance, "tournois", tournamentId);

    await updateDoc(tournamentRef, { closedTournamentLabel });

    await dispatch(fetchTournoi());

    return;
  }
);

export const changeParticipationPaymentStatus = createAsyncThunk<
  any,
  { participationId: string; status: string },
  { state: RootState }
>(
  "player/changeParticipationPaymentStatus",
  async ({ participationId, status }, { getState, dispatch }) => {
    const participationRef = doc(
      firestoreInstance,
      "participation",
      participationId
    );
    await updateDoc(participationRef, { paymentStatus: status });
    await dispatch(fetchAllRegistrations({ reload: true }));
    return;
  }
);

const tournoiSlice = createSlice({
  name: "tournoi",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchTournoi.fulfilled,
        (state: TournoiState, action: PayloadAction<Tournoi>) => {
          state.tournoi = action.payload;
        }
      )
      .addCase(
        fetchBoards.fulfilled,
        (state: TournoiState, action: PayloadAction<Tableau[]>) => {
          state.boards = action.payload.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        }
      )
      .addCase(
        fetchBoardRegistrations.fulfilled,
        (state: TournoiState, action: PayloadAction<Tableau[]>) => {
          state.boardRegistrations = action.payload.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          state.boardRegistrationsLoading = false;
        }
      )
      .addCase(fetchBoardRegistrations.pending, (state: TournoiState) => {
        state.boardRegistrationsLoading = true;
        state.boardRegistrations = [];
      })
      .addCase(
        fetchRounds.fulfilled,
        (state: TournoiState, action: PayloadAction<Tour[]>) => {
          state.rounds = action.payload.sort((a, b) => a.ordre - b.ordre);
        }
      )
      .addCase(
        fetchAllRegistrations.fulfilled,
        (state: TournoiState, action: PayloadAction<Participation[]>) => {
          state.allRegistrations = action.payload;
          state.allRegistrationsLoading = false;
        }
      )
      .addCase(fetchAllRegistrations.pending, (state: TournoiState, action) => {
        const { meta } = action;
        if (meta && meta.arg && meta.arg.reload) {
          return;
        }
        // Reset registrations only if reload is not requested
        state.allRegistrationsLoading = true;
        state.allRegistrations = [];
      });
  },
});

export default tournoiSlice.reducer;

export const getTournoi = (state: RootState): Tournoi => state.tournoi.tournoi!;

export const getAreOpenedRegistrations = (state: RootState) => {
  const isAdmin = state.session.admin;
  const { isRegisterClosed, isRegisterClosedAdmin } = state.tournoi.tournoi!;
  return isAdmin ? !isRegisterClosedAdmin : !isRegisterClosed;
};

export const getBoards = (state: RootState) => state.tournoi.boards;

export const getRounds = (state: RootState) => state.tournoi.rounds;

export const getBoardRegistrations = (state: RootState) =>
  state.tournoi.boardRegistrations;

export const getBoardRegistrationsLoading = (state: RootState) =>
  state.tournoi.boardRegistrationsLoading;

export const getAllRegistrations = (state: RootState) =>
  state.tournoi.allRegistrations;

export const getAllRegistrationsLoading = (state: RootState) =>
  state.tournoi.allRegistrationsLoading;

export const getLiveRefreshPeriodInSeconds = (state: RootState) =>
  state.tournoi.tournoi?.liveRefreshPeriodInSeconds
    ? state.tournoi.tournoi?.liveRefreshPeriodInSeconds * 1000
    : 10000;
